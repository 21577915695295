import { IAction, Td, Tr } from '@patternfly/react-table';
import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Alert } from '@patternfly/react-core';

import { StoreState } from '../../../store/store';
import { doseSelectors, doseTargetPrescriptionSet, doseTargetRegExpSet, doseTargetRoiNameSet } from '../../../store/dose/doseSlice';
import { CustomizationObjectType, SelectedDoseUnit } from '../../../store/global-types/customization-types';
import RegexField from '../../../components/regex-field';
import { columnCssNames, columnIds } from './DoseTargetTable';
import NumberInput from '../../../components/number-input';
import { ItemActionsColumn } from '../contour/ItemActionsColumn';
import { TargetMethod } from '../../../store/dose/dose-types';
import DebouncedTextInput from '../../../components/debounced-text-input';

interface DoseTargetItemProps {
    targetId: string;
    extraColumnsBefore?: ReactNode[],
    extraColumnsAfter?: ReactNode[],
    rowActions?: IAction[];
}

const DoseTargetItem = (props: DoseTargetItemProps) => {
    const { targetId, rowActions, extraColumnsBefore, extraColumnsAfter } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const target = useSelector((state: StoreState) => doseSelectors.selectTargetById(state, targetId));
    const output = useSelector((state: StoreState) => target !== undefined && target.outputId !== undefined ? doseSelectors.selectOutputById(state, target?.outputId) : undefined);

    const validationError = useSelector((state: StoreState) => doseSelectors.selectCustomizationValidationError(state, targetId));

    const handleTargetRegExpChanged = useCallback((regExp: string) => {
        dispatch(doseTargetRegExpSet({ id: targetId, regExp, }));
    }, [dispatch, targetId]);

    const handleTargetPrescriptionChanged = useCallback((prescription: number | null) => {
        if (!target) { throw new Error('Target is not defined'); }

        dispatch(doseTargetPrescriptionSet({ id: targetId, doseValue: prescription }));
    }, [dispatch, targetId, target]);

    const handleRoiNameChanged = useCallback((value: string) => {
        if (!target) { throw new Error('Target is not defined'); }

        dispatch(doseTargetRoiNameSet({ id: targetId, roiName: value }));
    }, [dispatch, targetId, target]);

    if (target === undefined || output === undefined) {
        return null;
    }

    const hasValidationError = validationError !== undefined && validationError.type === CustomizationObjectType.Target;

    const isFixedMethod = output.targetMethod === TargetMethod.Fixed;
    const unit = output.targetUnit === SelectedDoseUnit.Gy ? 'Gy' : output.targetUnit === SelectedDoseUnit.cGy ? 'cGy' : undefined;

    // TODO: consider allowing user to go back from regex to plain text in fixed target method mode
    const alwaysUseRegex = true;
    // const alwaysUseRegex = !isFixedMethod;

    return (
        <>
            <Tr key={targetId} className={`roi-item ${hasValidationError ? 'validation-error' : undefined}`}>
                <Td className={columnCssNames.modified}><div className="roi-item-is-modified" title="This customization has unsaved changes.">{target.isModified ? '*' : <>&nbsp;</>}</div></Td>

                {extraColumnsBefore && extraColumnsBefore.map(extraColumn => extraColumn)}

                <Td dataLabel={columnIds.matchPattern} className={columnCssNames.matchPattern}>
                    <div>
                        <RegexField
                            id={targetId}
                            label={undefined}
                            onChange={handleTargetRegExpChanged}
                            regexValue={target.regExp}
                            alwaysUseRegex={alwaysUseRegex}
                        />
                    </div>
                </Td>

                {isFixedMethod && (
                    <Td dataLabel={columnIds.prescription} className={columnCssNames.prescription}>
                        <FormGroup>
                            <NumberInput
                                fieldId={`prescription-field-for-${targetId}`}
                                onChange={handleTargetPrescriptionChanged}
                                defaultValue={target.prescription || undefined}
                                lowerBound={0}
                                unit={unit}
                            />
                        </FormGroup>
                    </Td>
                )}

                <Td dataLabel={columnIds.roiName} className={columnCssNames.roiName}>
                    <div>
                        <DebouncedTextInput
                            fieldId={`target-${targetId}-roi-name`}
                            onDebouncedChange={handleRoiNameChanged}
                            defaultValue={target.roiName}
                            placeholder={t('customizationPage.dose.targets.targetName.placeholder')}
                        />
                    </div>
                </Td>

                {extraColumnsAfter && extraColumnsAfter.map(extraColumn => extraColumn)}

                <Td isActionCell>
                    {rowActions && <ItemActionsColumn items={rowActions} />}
                </Td>
            </Tr>

            {hasValidationError && (
                <Tr className="roi-item validation-error validation-error-box selectable">
                    <Td dataLabel="Validation error" noPadding={true} colSpan={100}>
                        <Alert variant="danger" isInline isPlain isExpandable title={`Validation error: ${validationError.message}`}>
                            {validationError.field && (<div>Field: {validationError.field}</div>)}
                            <div>Error type: {validationError.detail}</div>
                            <div>Error ctx: {validationError.ctx}</div>
                        </Alert>
                    </Td>
                </Tr>
            )}

        </>
    );
}

export default DoseTargetItem;
