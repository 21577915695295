import { StackItem, Split, SplitItem, TextContent, Text, Alert, Stack } from '@patternfly/react-core';
import React from 'react';
import { BackendValidationErrorViewModel } from '../../store/global-types/store-errors';


interface ModelSelectionRuleWrapperProps {
    title: string,
    isModified: boolean,
    hasValidationError: boolean,
    className?: string,
    validationError: BackendValidationErrorViewModel | undefined,
}

const ModelSelectionRuleWrapper = (props: React.PropsWithChildren<ModelSelectionRuleWrapperProps>) => {
    const { title, isModified, hasValidationError, validationError, children, className } = props;

    return (
        <StackItem className={`selection-rule-wrapper ${className || ''} ${hasValidationError ? 'validation-error' : ''}`}>
            <div className="model-selection-rule">
                <Stack>
                    <StackItem>
                        <Split>
                            <SplitItem className="rule-type">
                                <TextContent>
                                    <Text>{title}{`${isModified ? '*' : ''}`}</Text>
                                </TextContent>
                            </SplitItem>
                            <SplitItem isFilled className="rule-settings">
                                {children}
                            </SplitItem>
                        </Split>
                    </StackItem>
                    {
                        hasValidationError && validationError && (
                            <StackItem className="selection-rule-wrapper validation-error validation-error-box selectable">
                                <Alert variant="danger" isInline isPlain isExpandable title={`Validation error: ${validationError.message}`}>
                                    <div>Error type: {validationError.detail}</div>
                                    <div>Error ctx: {validationError.ctx}</div>
                                </Alert>
                            </StackItem>
                        )
                    }
                </Stack>
            </div>
        </StackItem>

    );
}

export default ModelSelectionRuleWrapper;
