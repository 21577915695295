import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { configurationTargetSelectors, currentConfigurationTargetSet, credentialsRegenerationRequested, tokenAssociationApproved } from '../../store/configurationTarget/configurationTargetSlice';
import Select from 'react-select';
import DaemonConfigItem from './DaemonConfigItem';
import { selectStyles } from '../../components/client-selector';
import { Alert } from '@patternfly/react-core';

const BROSER_FLOW = "browser";
const TOKEN_FLOW = "token";

/**
 * This page is the form that gets rendered when Cloud configuration is triggered from the Mvision Configurator
 */
const CloudConfigPage = () => {
    const params = new URLSearchParams(useLocation().search);
    const targetList = useSelector(configurationTargetSelectors.selectConfigurationTargetList) || [];
    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const daemonCredentials = useSelector(configurationTargetSelectors.daemonCredentials);
    const { t } = useTranslation();

    const sessionID = useMemo(() => {return params.get("service")}, [params]);
    const token = useMemo(() => {return params.get("token")}, [params]);
    const flow = useMemo(() => {return params.get("flow")}, [params]);

    const dispatch = useDispatch();

    const changeConfigurationTarget = (newTargetId: string) => {
        if (newTargetId !== currentTarget?.userId) {
            const newValue = newTargetId === '' ? undefined : newTargetId;

            dispatch(currentConfigurationTargetSet(newValue));
            // Daemon is now known - we can  regenerate credentials
            if (sessionID && (flow === BROSER_FLOW)) dispatch(credentialsRegenerationRequested({ appClient: newValue, sessionID: sessionID }));
        }
    }

    if ((flow !== BROSER_FLOW) && (flow !== TOKEN_FLOW)) {
        return <div className="logged-in-container">Configuration flow {flow} is not supported!</div>
    }

    return (
        <div className="logged-in-container" style={{ display: 'flex', flexDirection: 'column' }}>
            {
                !!currentTarget && !!daemonCredentials.error && <Alert variant='danger' title="Error happened during the credentials generation!" />
            }
            {(currentTarget == null) && targetList.length > 0 &&
                <Select
                    className="client-selector-dropdown"
                    onChange={(selectedOption: any) => {
                        if (selectedOption) {
                            changeConfigurationTarget(selectedOption.value);
                        }
                    }}
                    isDisabled={currentTarget != null}
                    value={currentTarget}
                    options={targetList.map(target => ({ value: target.userId, label: target.userName }))}
                    placeholder={t('selectConfigurationTarget.placeholder')}
                    maxMenuHeight={700}
                    styles={selectStyles}
                />}
            {(currentTarget != null) && <DaemonConfigItem daemonConfig={{
                aeTitle: '',
                sessionId: sessionID || 'default',
                listenIp: '0.0.0.0',
                listenPort: '11112',
                forwardImages: false,
                auditLog: false,
                maxTries: '5',
                targets: [],
                keepMappingInH: '0',
                backendConfigIntervalInS: '30',
                backendPollIntervalInS: '5',
                passThroughAttributes: [],
                retainAttributes: []
            }} runningNumber={1} isBootstrap={true} onConfigSaved={(config: any) => {
                if (flow === BROSER_FLOW) {
                    if (window) {
                        const override = {
                            ...config,
                            ad_backend_id: "ebe6f1a7-69db-46b0-85aa-334284979346",
                            ad_client_id: daemonCredentials.key,
                            ad_client_key: daemonCredentials.secret
                        };
                        const url = `${params.get('callback')}${btoa(JSON.stringify(override))}`;
                        const w = window.open(url);

                        if (w) w.focus();
                    }
                }
                else if (flow === TOKEN_FLOW) {
                    // Notify backend that the certain token is associated with that configuration
                    dispatch(tokenAssociationApproved({ token: token || undefined, sessionID: sessionID || undefined }))
                }
            }} />}
        </div>
    );
}

export default CloudConfigPage;
