import { useCallback, useMemo, useState } from 'react';
import { Toolbar, ToolbarContent, ToolbarItem, Button, Modal, ModalVariant, Alert, TextContent, Text, MenuToggle, Dropdown, DropdownItem } from '@patternfly/react-core';
import { useSelector } from 'react-redux';
import { SaveIcon, UndoIcon, PlusIcon, TrashIcon, PencilAltIcon, EllipsisVIcon, HistoryIcon } from '@patternfly/react-icons'
import { Trans, useTranslation } from 'react-i18next';

import { CustomizationBase, CustomizationObjectType, CustomizationOutput, DEFAULT_MODEL_CUSTOMIZATION_NAME, Model, ModelType } from '../../store/global-types/customization-types';
import { generateNewId } from '../../store/global-types/customization-helpers';
import CustomizationNameField, { defaultDuplicatedModelNameValidation, validateDuplicatedModelName } from './CustomizationNameField';
import RenameCustomizationModal from './RenameCustomizationModal';
import ConfirmationModal from '../../components/confirmation-modal';
import OutputFilenameField from './OutputFilenameField';
import { hasUserName, MVisionAppClient } from '../../store/configurationTarget/mvision-client-list';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import { notEmpty } from '../../util/filter';

import './customization-page.css';
import { getModelTypeLangKey } from '../../i18n';

export type CustomizationNameAndModelId = { customizationName: string; modelId: string; }


interface CustomizationToolbarProps {
    customizationOutputId?: string,
    currentView: CustomizationObjectType,
    modelType: ModelType,

    allCustomizationNamesAndModelIds: CustomizationNameAndModelId[],
    isAnyCustomizationModelModified: boolean,
    customizationOutput: CustomizationOutput | undefined,
    customizationBase: CustomizationBase | undefined,
    model: Model | undefined,

    modelCustomizationSaveError: string | null,
    modelCustomizationDataError: string | null,
    isSavingInProgress: boolean,
    isCustomizationOutputResetInProgress: boolean,
    isCustomizationResetInProgress: boolean,

    onUndo?: () => void,
    onSave?: (currentTarget: MVisionAppClient) => void,
    onAddNewCustomization?: (customizationBaseId: string, newCustomizationName: string) => void,
    onAddNewOutput?: (customizationOutputId: string, newFilename: string) => void,
    onDeleteCustomization?: (customizationBaseId: string) => void,
    onDeleteOutput?: (customizationOutputId: string) => void,
    onResetCustomization?: (customizationOutputId: string) => void,
    onResetOutput?: (customizationOutputId: string) => void,
    onRenameCustomization?: (customizationBaseId: string, newCustomizationName: string) => void,

    onAddAdvancedStructure?: (customizationOutputId: string, newRoiId: string) => void,
}

const CustomizationToolbar = (props: CustomizationToolbarProps) => {
    const { customizationOutputId, currentView, modelType,
        allCustomizationNamesAndModelIds, isAnyCustomizationModelModified,
        customizationOutput, customizationBase, model,
        modelCustomizationSaveError, modelCustomizationDataError, isSavingInProgress, isCustomizationResetInProgress, isCustomizationOutputResetInProgress,
        onUndo, onSave, onAddNewCustomization, onAddNewOutput, onDeleteCustomization, onDeleteOutput, onResetCustomization, onResetOutput,
        onRenameCustomization,
        onAddAdvancedStructure, } = props;

    const { t } = useTranslation();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isUndoConfirmationModalOpen, setUndoConfirmationModalOpenState] = useState(false);
    const [isSaveConfirmationModalOpen, setSaveConfirmationModalOpenState] = useState(false);
    const [isAddNewCustomizationModalOpen, setAddNewCustomizationModalOpen] = useState(false);
    const [isAddNewFileModalOpen, setAddNewFileModalOpen] = useState(false);
    const [isRenameCustomizationModalOpen, setRenameCustomizationModalOpen] = useState(false);
    const [isDeleteCustomizationModalOpen, setDeleteCustomizationModalOpen] = useState(false);
    const [isDeleteOutputModalOpen, setDeleteOutputModalOpen] = useState(false);
    const [isResetCustomizationModalOpen, setResetCustomizationModalOpen] = useState(false);
    const [isResetOutputModalOpen, setResetOutputModalOpen] = useState(false);
    const [duplicatedCustomizationName, setDuplicatedCustomizationName] = useState('');
    const [duplicatedOutputFilename, setDuplicatedOutputFilename] = useState('');
    const [duplicatedCustomizationNameValidation, setDuplicatedCustomizationNameValidation] = useState(defaultDuplicatedModelNameValidation);

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);

    const noChanges = !isAnyCustomizationModelModified;

    const handleToggleDropdownOpen = useCallback(() => {
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen]);

    const handleOpenUndoDialog = useCallback(() => {
        setUndoConfirmationModalOpenState(true);
    }, []);

    const handleCloseUndoDialog = useCallback(() => {
        setUndoConfirmationModalOpenState(false);
    }, []);

    const handleOpenSaveDialog = useCallback(() => {
        setSaveConfirmationModalOpenState(true);
    }, []);

    const handleCloseSaveDialog = useCallback(() => {
        setSaveConfirmationModalOpenState(false);
    }, []);

    const handleOpenAddNewCustomizationModalDialog = useCallback(() => {
        setAddNewCustomizationModalOpen(true);
    }, []);

    const handleCloseAddNewCustomizationModalDialog = useCallback(() => {
        setAddNewCustomizationModalOpen(false);
    }, []);

    const handleOpenAddNewFileModalDialog = useCallback(() => {
        setAddNewFileModalOpen(true);
    }, []);

    const handleCloseAddNewFileModalDialog = useCallback(() => {
        setAddNewFileModalOpen(false);
    }, []);

    const handleOpenRenameCustomizationModalDialog = useCallback(() => {
        setRenameCustomizationModalOpen(true);
    }, []);

    const handleCloseRenameCustomizationModalDialog = useCallback(() => {
        setRenameCustomizationModalOpen(false);
    }, []);

    const handleOpenDeleteCustomizationModalDialog = useCallback(() => {
        setDeleteCustomizationModalOpen(true);
    }, []);

    const handleCloseDeleteCustomizationModalDialog = useCallback(() => {
        setDeleteCustomizationModalOpen(false);
    }, []);

    const handleOpenDeleteOutputModalDialog = useCallback(() => {
        setDeleteOutputModalOpen(true);
    }, []);

    const handleCloseDeleteOutputModalDialog = useCallback(() => {
        setDeleteOutputModalOpen(false);
    }, []);

    const handleOpenResetCustomizationModalDialog = useCallback(() => {
        setResetCustomizationModalOpen(true);
    }, []);

    const handleCloseResetCustomizationModalDialog = useCallback(() => {
        setResetCustomizationModalOpen(false);
    }, []);

    const handleOpenResetOutputModalDialog = useCallback(() => {
        setResetOutputModalOpen(true);
    }, []);

    const handleCloseResetOutputModalDialog = useCallback(() => {
        setResetOutputModalOpen(false);
    }, []);

    const handleUndo = useCallback(() => {
        if (onUndo && !isSavingInProgress) {
            onUndo();
            handleCloseUndoDialog();
        }
    }, [onUndo, handleCloseUndoDialog, isSavingInProgress]);

    const handleSave = useCallback(() => {
        if (onSave && !isSavingInProgress) {
            if (!currentTarget) {
                throw new Error('No configuration target set -- cannot save');
            }
            onSave(currentTarget);
            handleCloseSaveDialog();
        }
    }, [onSave, currentTarget, isSavingInProgress, handleCloseSaveDialog]);

    const handleAddNewCustomization = useCallback(() => {
        if (onAddNewCustomization && customizationBase) {
            onAddNewCustomization(customizationBase.id, duplicatedCustomizationName);
        }

        setAddNewCustomizationModalOpen(false);
        setDuplicatedCustomizationName('');
    }, [onAddNewCustomization, customizationBase, duplicatedCustomizationName]);

    const handleAddNewOutput = useCallback(() => {
        if (onAddNewOutput && customizationOutputId) {
            onAddNewOutput(customizationOutputId, duplicatedOutputFilename);
        }

        setAddNewFileModalOpen(false);
        setDuplicatedOutputFilename('');
    }, [onAddNewOutput, customizationOutputId, duplicatedOutputFilename]);

    const handleCustomizationDelete = useCallback(() => {
        handleCloseDeleteCustomizationModalDialog();
        if (onDeleteCustomization && customizationBase) {
            onDeleteCustomization(customizationBase.id);
        }
    }, [onDeleteCustomization, handleCloseDeleteCustomizationModalDialog, customizationBase]);

    const handleOutputDelete = useCallback(() => {
        handleCloseDeleteOutputModalDialog();
        if (onDeleteOutput && customizationOutput) {
            onDeleteOutput(customizationOutput.id);
        }
    }, [onDeleteOutput, handleCloseDeleteOutputModalDialog, customizationOutput]);

    const handleCustomizationReset = useCallback(() => {
        handleCloseResetCustomizationModalDialog();
        if (onResetCustomization && customizationBase) {
            onResetCustomization(customizationBase.id);
        }
    }, [onResetOutput, handleCloseResetOutputModalDialog, customizationOutput]);

    const handleOutputReset = useCallback(() => {
        handleCloseResetOutputModalDialog();
        if (onResetOutput && customizationOutput) {
            onResetOutput(customizationOutput.id);
        }
    }, [onResetOutput, handleCloseResetOutputModalDialog, customizationOutput]);

    const handleAddAdvancedStructure = useCallback(() => {
        if (onAddAdvancedStructure && customizationOutputId) {
            const newRoiId = generateNewId();
            onAddAdvancedStructure(customizationOutputId, newRoiId);
        }
    }, [onAddAdvancedStructure, customizationOutputId]);

    const handleDuplicatedCustomizationNameChange = useCallback((newCustomizationName: string) => {
        setDuplicatedCustomizationName(newCustomizationName);
        if (customizationBase && model) {
            const validationResult = validateDuplicatedModelName(allCustomizationNamesAndModelIds, newCustomizationName, customizationBase.modelId);
            setDuplicatedCustomizationNameValidation(validationResult);
        } else {
            throw new Error('Could not retrieve matching customization base and/or model -- cannot validate new name');
        }
    }, [allCustomizationNamesAndModelIds, customizationBase, model]);

    const handleDuplicatedOutputFilenameChange = useCallback((newFilename: string) => {
        setDuplicatedOutputFilename(newFilename);
    }, []);

    const isSaveModalActuallyOpen = isSaveConfirmationModalOpen || isSavingInProgress;

    const allowRenameCustomization = customizationBase !== undefined && customizationBase.customizationName !== DEFAULT_MODEL_CUSTOMIZATION_NAME;
    const allowRemoveCustomization = customizationBase !== undefined && customizationBase.customizationName !== DEFAULT_MODEL_CUSTOMIZATION_NAME;
    const allowRemoveOutput = customizationBase !== undefined && customizationBase.outputs.length > 1;

    const ellipsisMenuItems = useMemo(() => {
        return [
            onRenameCustomization ? <DropdownItem
                key="rename-customization"
                isDisabled={!allowRenameCustomization}
                title={!allowRenameCustomization ? `"${DEFAULT_MODEL_CUSTOMIZATION_NAME}" customizations cannot be renamed.` : undefined}
                icon={<PencilAltIcon />}
                onClick={handleOpenRenameCustomizationModalDialog}>
                {t('customizationToolbar.renameCustomization', { customizationName: customizationBase ? ` "${customizationBase.customizationName}"` : '' })}
            </DropdownItem> : null,
            onDeleteCustomization ? <DropdownItem
                key="remove-customization"
                isDisabled={!allowRemoveCustomization || model === undefined || model.customizations.length <= 1}
                title={!allowRemoveCustomization ? `"${DEFAULT_MODEL_CUSTOMIZATION_NAME}" customizations cannot be removed.` : undefined}
                icon={<TrashIcon />}
                onClick={handleOpenDeleteCustomizationModalDialog}>
                {t('customizationToolbar.removeCustomization', { customizationName: customizationBase ? `"${customizationBase.customizationName}"` : '' })}
            </DropdownItem> : null,
            onDeleteOutput ? <DropdownItem
                key="remove-output"
                isDisabled={currentView !== CustomizationObjectType.CustomizationOutput || !allowRemoveOutput}
                title={allowRemoveOutput ? 'Customizations must have at least one target file -- the last file cannot be removed.' : undefined}
                icon={<TrashIcon />}
                onClick={handleOpenDeleteOutputModalDialog}>
                {t('customizationToolbar.removeTargetFile', { targetFileName: customizationOutput ? `"${customizationOutput.filename}"` : '' })}
            </DropdownItem> : null,
            onResetCustomization ? <DropdownItem
                key="reset-customization"
                isDisabled={currentView !== CustomizationObjectType.CustomizationOutput && currentView !== CustomizationObjectType.CustomizationBase}
                title={t('customizationToolbar.resetTargetCustomization.title')}
                icon={<HistoryIcon />}
                onClick={handleOpenResetCustomizationModalDialog}>
                {t('customizationToolbar.resetTargetCustomization', { targetCustomization: customizationBase ? `"${customizationBase.customizationName}"` : '' })}
            </DropdownItem> : null,
            onResetOutput ? <DropdownItem
                key="reset-output"
                isDisabled={currentView !== CustomizationObjectType.CustomizationOutput}
                title={t('customizationToolbar.resetTargetFile.title')}
                icon={<HistoryIcon />}
                onClick={handleOpenResetOutputModalDialog}>
                {t('customizationToolbar.resetTargetFile', { targetFileName: customizationOutput ? `"${customizationOutput.filename}"` : '' })}
            </DropdownItem> : null
        ].filter(notEmpty);
    }, [onRenameCustomization, onDeleteCustomization, onDeleteOutput, onResetCustomization, onResetOutput,
        allowRenameCustomization, allowRemoveCustomization, allowRemoveOutput, currentView,
        customizationBase, customizationOutput]);

    return (
        <div className="model-customization-toolbar">
            {appConfig && appConfig.showErrorsInUi && modelCustomizationSaveError !== null && (
                <div className="toolbar-error selectable">
                    <Alert variant="danger" isInline title="An error occurred when trying to save model customization">
                        <p>{modelCustomizationSaveError}</p>
                    </Alert>
                </div>
            )}
            {appConfig && appConfig.showErrorsInUi && modelCustomizationDataError !== null && (
                <div className="toolbar-error selectable">
                    <Alert variant="danger" isInline title="An error occurred">
                        <p>{modelCustomizationDataError}</p>
                    </Alert>
                </div>
            )}
            <Toolbar>
                <ToolbarContent>
                    {onSave && (
                        <ToolbarItem>
                            <Button
                                variant="primary"
                                icon={<SaveIcon />}
                                isDisabled={noChanges}
                                onClick={handleOpenSaveDialog}>{t('common.saveAllChanges')}</Button>
                        </ToolbarItem>
                    )}
                    {onUndo && (
                        <ToolbarItem>
                            <Button
                                variant="danger"
                                icon={<UndoIcon />}
                                isDisabled={noChanges}
                                onClick={handleOpenUndoDialog}>{t('common.undoAllChanges')}</Button>
                        </ToolbarItem>
                    )}

                    {(currentView === CustomizationObjectType.CustomizationOutput || currentView === CustomizationObjectType.CustomizationBase) &&
                        (onAddNewCustomization || onAddNewOutput) && (
                            <>
                                <ToolbarItem variant="separator" />
                                {onAddNewCustomization && (
                                    <ToolbarItem>
                                        <Button
                                            variant="secondary"
                                            icon={<PlusIcon />}
                                            title="Add a new customization for the selected model"
                                            onClick={handleOpenAddNewCustomizationModalDialog}>{t('customizationToolbar.addCustomization')}</Button>
                                    </ToolbarItem>
                                )}
                                {onAddNewOutput && (
                                    <ToolbarItem>
                                        <Button
                                            variant="secondary"
                                            icon={<PlusIcon />}
                                            title="Add another target file for the selected customization"
                                            onClick={handleOpenAddNewFileModalDialog}>{t('customizationToolbar.addFile')}</Button>
                                    </ToolbarItem>
                                )}
                            </>)}

                    {currentView === CustomizationObjectType.CustomizationOutput &&
                        (onAddAdvancedStructure) && (
                            <>
                                <ToolbarItem variant="separator" />
                                <ToolbarItem>
                                    <Button
                                        variant="secondary"
                                        icon={<PlusIcon />}
                                        onClick={handleAddAdvancedStructure}>{t('customizationToolbar.addAdvancedRoi')}</Button>
                                </ToolbarItem>
                            </>)}

                    {(currentView === CustomizationObjectType.CustomizationBase || currentView === CustomizationObjectType.CustomizationOutput)
                        && (onRenameCustomization || onDeleteCustomization || onDeleteOutput || onResetOutput) && (
                            <>
                                <ToolbarItem variant="separator" />
                                <ToolbarItem align={{ default: 'alignRight' }}>
                                    <Dropdown
                                        toggle={(toggleRef) => <MenuToggle ref={toggleRef} onClick={handleToggleDropdownOpen} icon={<EllipsisVIcon />} variant='plain'><EllipsisVIcon /></MenuToggle>}
                                        isOpen={isDropdownOpen}
                                        isPlain
                                        isRootMenu
                                        onOpenChange={handleToggleDropdownOpen}
                                        popperProps={{ position: 'right' }}
                                        shouldFocusFirstItemOnOpen={false}>
                                        {ellipsisMenuItems}
                                    </Dropdown>
                                </ToolbarItem>
                            </>)}

                </ToolbarContent>
            </Toolbar>

            <Modal
                variant={ModalVariant.large}
                title={hasUserName(currentTarget) ?
                    (<Trans i18nKey="customizationToolbar.save.confirm.title.hasCurrentUserName">Save {{ modelType: t(getModelTypeLangKey(modelType)) }} customizations for {{ currentUser: currentTarget!.userName }}?</Trans>) :
                    (<Trans i18nKey="customizationToolbar.save.confirm.title.noCurrentUserName">Save {{ modelType: t(getModelTypeLangKey(modelType)) }} customizations?</Trans>)}
                isOpen={isSaveModalActuallyOpen}
                onClose={handleCloseSaveDialog}
                actions={[
                    <Button key="confirmSave" variant="secondary" isLoading={isSavingInProgress} onClick={handleSave}>{t('common.saveAll')}</Button>,
                    <Button key="cancel" disabled={isSavingInProgress} variant="tertiary" onClick={handleCloseSaveDialog}>{t('common.cancel')}</Button>
                ]}
            >
                {hasUserName(currentTarget) ? (
                    <Trans i18nKey="customizationToolbar.save.confirm.text.hasCurrentUserName">
                        Do you want to overwrite existing {{ modelType: t(getModelTypeLangKey(modelType)) }} customizations for {{ currentUser: currentTarget!.userName }} with your changes? Note
                        that this will save <b>all</b> modifications to customizations for <b>all structures and models</b>. Any previous customizations
                        are lost. You can always revert back to default settings.
                    </Trans>
                ) : (
                    <Trans i18nKey="customizationToolbar.save.confirm.text.noCurrentUserName">
                        Do you want to overwrite existing {{ modelType: t(getModelTypeLangKey(modelType)) }} customizations with your changes? Note
                        that this will save <b>all</b> modifications to customizations for <b>all structures and models</b>. Any previous customizations
                        are lost. You can always revert back to default settings.
                    </Trans>
                )}
            </Modal>

            <Modal
                isOpen={isUndoConfirmationModalOpen}
                onClose={handleCloseUndoDialog}
                variant={ModalVariant.small}
                title={(<Trans i18nKey="customizationToolbar.undo.confirm.title">Confirm undo for {{ modelType: t(getModelTypeLangKey(modelType)) }} changes</Trans>)}
                actions={[
                    <Button key="confirmUndo" variant="danger" onClick={handleUndo}>{t('common.undoAllChanges')}</Button>,
                    <Button key="cancelUndo" variant="secondary" onClick={handleCloseUndoDialog}>{t('common.keepChanges')}</Button>
                ]}
            >
                <Trans i18nKey="customizationToolbar.undo.confirm.text">
                    Are you sure you wish to undo all changes to {{ modelType: t(getModelTypeLangKey(modelType)) }} customizations? Note that this will revert <b>all</b> modifications to customizations
                    for <b>all structures and models</b>. All changes that have not been saved will be lost.
                </Trans>
            </Modal>

            {customizationBase && model && (
                <Modal
                    isOpen={isAddNewCustomizationModalOpen}
                    onClose={handleCloseAddNewCustomizationModalDialog}
                    variant={ModalVariant.medium}
                    title={t('customizationToolbar.addCustomization.title')}
                    className="duplicate-model-modal"
                    position="top"
                    positionOffset="15%"
                    actions={[
                        <Button key="confirmAdd" variant="primary" isDisabled={duplicatedCustomizationNameValidation.validated === 'error' || !duplicatedCustomizationName} onClick={handleAddNewCustomization}>{t('customizationToolbar.addNewCustomization')}</Button>,
                        <Button key="cancelAdd" variant="tertiary" onClick={handleCloseAddNewCustomizationModalDialog}>{t('common.cancel')}</Button>
                    ]}
                >
                    <TextContent className="mv-section">
                        <Text component="p">{t('customizationToolbar.addCustomization.paragraph1')}</Text>
                    </TextContent>

                    <div>
                        <TextContent className="mv-section">
                            <Text component="p">{t('customizationToolbar.addCustomization.paragraph2')}</Text>
                        </TextContent>

                        <TextContent className="mv-section">
                            <Text component="p">{t('customizationToolbar.addCustomization.paragraph3')}</Text>
                        </TextContent>

                        <div className="mv-section">
                            <CustomizationNameField
                                modelName={model.modelName}
                                sourceCustomizationName={customizationBase.customizationName}
                                customizationNameValidation={duplicatedCustomizationNameValidation}
                                onCustomizationNameChange={handleDuplicatedCustomizationNameChange}
                                customizationName={duplicatedCustomizationName}
                            />
                        </div>
                    </div>

                </Modal>
            )}

            {customizationBase && model && customizationOutput && (
                <Modal
                    isOpen={isAddNewFileModalOpen}
                    onClose={handleCloseAddNewFileModalDialog}
                    variant={ModalVariant.medium}
                    title={t('customizationToolbar.addOutput.title')}
                    className="duplicate-model-modal"
                    position="top"
                    positionOffset="15%"
                    actions={[
                        <Button key="confirmAdd" variant="primary" isDisabled={!duplicatedOutputFilename} onClick={handleAddNewOutput}>{t('customizationToolbar.addNewTargetFile')}</Button>,
                        <Button key="cancelAdd" variant="tertiary" onClick={handleCloseAddNewFileModalDialog}>{t('common.cancel')}</Button>
                    ]}
                >
                    <TextContent className="mv-section">
                        <Text component="p">{t('customizationToolbar.addOutput.paragraph1')}</Text>
                    </TextContent>

                    <div>
                        <TextContent className="mv-section">
                            <Text component="p">{t('customizationToolbar.addOutput.paragraph2')}</Text>
                        </TextContent>

                        <TextContent className="mv-section">
                            <Text component="p">{t('customizationToolbar.addOutput.paragraph3')}</Text>
                        </TextContent>

                        <div className="mv-section">
                            <OutputFilenameField
                                modelName={model.modelName}
                                sourceCustomizationName={customizationBase.customizationName}
                                sourceFilename={customizationOutput.filename}
                                duplicatedFilename={duplicatedOutputFilename}
                                onOutputFilenameChange={handleDuplicatedOutputFilenameChange}
                            />
                        </div>
                    </div>

                </Modal>
            )}

            {customizationBase && model && (
                <>
                    {onRenameCustomization && (
                        <RenameCustomizationModal
                            isOpen={isRenameCustomizationModalOpen}
                            onClose={handleCloseRenameCustomizationModalDialog}
                            model={model}
                            customization={customizationBase}
                            onRenameModelCustomization={onRenameCustomization}
                            allCustomizationNamesAndModelIds={allCustomizationNamesAndModelIds}
                        />
                    )}

                    <ConfirmationModal
                        isOpen={isDeleteCustomizationModalOpen}
                        title="Confirm deletion of customization"
                        onConfirm={handleCustomizationDelete}
                        onClose={handleCloseDeleteCustomizationModalDialog}
                        confirmLabel="Delete"
                        variant={ModalVariant.small}
                        confirmVariant="danger"
                    >
                        Are you sure you wish to delete customization {customizationBase.customizationName} from {model.modelName}?
                    </ConfirmationModal>
                </>
            )}

            {customizationOutput && customizationBase && model && (
                <ConfirmationModal
                    isOpen={isDeleteOutputModalOpen}
                    title="Confirm deletion of target file"
                    onConfirm={handleOutputDelete}
                    onClose={handleCloseDeleteOutputModalDialog}
                    confirmLabel="Delete"
                    variant={ModalVariant.small}
                    confirmVariant="danger"
                >
                    Are you sure you wish to delete target file {customizationOutput.filename} from customization {customizationBase.customizationName} (from {model.modelName})?
                </ConfirmationModal>
            )}

            {customizationBase && model && (
                <ConfirmationModal
                    isOpen={isResetCustomizationModalOpen || isCustomizationResetInProgress}
                    title={t('customizationToolbar.resetTargetCustomization.confirm.title')}
                    onConfirm={handleCustomizationReset}
                    onClose={handleCloseResetCustomizationModalDialog}
                    confirmLabel={t('common.reset')}
                    variant={ModalVariant.small}
                    confirmVariant="danger"
                    isLoadingInProgress={isCustomizationResetInProgress}
                >
                    {t('customizationToolbar.resetTargetCustomization.confirm.message',
                        { targetCustomizationName: customizationBase.customizationName, modelName: model.label })}
                </ConfirmationModal>
            )}

            {customizationOutput && customizationBase && model && (
                <ConfirmationModal
                    isOpen={isResetOutputModalOpen || isCustomizationOutputResetInProgress}
                    title={t('customizationToolbar.resetTargetFile.confirm.title')}
                    onConfirm={handleOutputReset}
                    onClose={handleCloseResetOutputModalDialog}
                    confirmLabel={t('common.reset')}
                    variant={ModalVariant.small}
                    confirmVariant="danger"
                    isLoadingInProgress={isCustomizationOutputResetInProgress}
                >
                    {t('customizationToolbar.resetTargetFile.confirm.message',
                        { targetFileName: customizationOutput.filename, customizationName: customizationBase.customizationName, segmentationModelName: model.label })}
                </ConfirmationModal>
            )}


        </div>
    );
}

export default CustomizationToolbar;
