import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, FlexItem, TextInput, FormGroup, Alert, TextInputGroup } from '@patternfly/react-core';

import ColorPicker from '../../../components/color-picker';
import { useScroll } from '../../../hooks/useScroll';
import { isContouringRoi } from '../../../store/contouring/contouring-types';
import TextOverrideField from '../../../components/text-override-field';
import { IAction, Td, Tr } from '@patternfly/react-table';
import { columnCssNames, columnIds } from './ContourRoiTable';
import InterpretedTypeDropdown from './InterpretedTypeDropdown';
import { useTranslation } from 'react-i18next';
import RoiOperationModal from '../../../components/roi-operation-modal';
import { getFormValidationErrorMessage } from '../../../store/global-types/form-errors';
import { defaultValidation, FormValidationState } from '../../../components/form-validation-state';
import { StoreState } from '../../../store/store';
import { contouringSelectors, roiCodingSchemeUpdated, roiCustomizationColorUpdated, roiCustomizationIncludedInModelUpdated, roiCustomizationNameUpdated, roiCustomizationOperationUpdated, roiInterpretedTypeUpdated, scrollToViewFromRoiCustomizationRemoved } from '../../../store/contouring/contouringSlice';
import { makeSelectFormValidationErrorsForRoi } from '../../../store/contouring/contouringSelectors';
import { CustomizationObjectType } from '../../../store/global-types/customization-types';
import ValidationHelperText from '../../../components/validation-helper-text';
import { ItemActionsColumn } from './ItemActionsColumn';
import AppendedButton from '../../../components/appended-button';
interface RoiItemProps {
    roiId: string;
    isGlobalRoi?: boolean;
    hideIncludedOption?: boolean;
    hideIdentificationCodeFields?: boolean;
    extraColumnsBefore?: ReactNode[],
    extraColumnsAfter?: ReactNode[],
    rowActions?: IAction[];
    expandedRow?: string | undefined;
}

const emptyOperationValidation: FormValidationState = { validated: 'error', helperTextInvalid: 'Operation must not be empty.' };

const RoiItem = (props: RoiItemProps) => {
    const { roiId, hideIncludedOption, hideIdentificationCodeFields, rowActions, extraColumnsBefore, extraColumnsAfter, expandedRow } = props;
    const isGlobalRoi = props.isGlobalRoi === true;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const roi = useSelector((state: StoreState) => isGlobalRoi ?
        contouringSelectors.selectGlobalRoiById(state, roiId) :
        contouringSelectors.selectRoiById(state, roiId));
    const originalRoi = useSelector((state: StoreState) => isGlobalRoi ? undefined : contouringSelectors.selectOriginalRoiById(state, roiId));
    const validationError = useSelector((state: StoreState) => contouringSelectors.selectCustomizationValidationError(state, roiId));

    const selectFormValidationErrorsForRoi = useMemo(makeSelectFormValidationErrorsForRoi, []);
    const formValidationErrors = useSelector((state: StoreState) => selectFormValidationErrorsForRoi(state, roiId));

    const [executeScroll, scrollRef] = useScroll<HTMLDivElement>();
    const [isHighlightedItem, setIsHighlightedItem] = useState(false);

    /** Scroll the viewport to a correct position if an advanced ROI was just added. */
    useEffect(() => {
        if (roi && scrollRef && isContouringRoi(roi) && roi.scrollToView) {
            setIsHighlightedItem(true);
            dispatch(scrollToViewFromRoiCustomizationRemoved(roiId));
            executeScroll();
        }
    }, [executeScroll, roi, scrollRef, dispatch, roiId]);

    const [nameFieldValidation, setNameFieldValidation] = useState<FormValidationState>(defaultValidation);
    const [operationFieldValidation, setOperationFieldValidation] = useState<FormValidationState>(defaultValidation);
    const [isRoiOperationModalOpen, setIsRoiOperationModalOpen] = useState(false);

    /** Set non-blocking form validation errors generated in redux store reducers to be visible in this form's normal UI validation. */
    useEffect(() => {
        if (formValidationErrors.length > 0) {
            setNameFieldValidation({ validated: 'error', helperTextInvalid: formValidationErrors.map(e => getFormValidationErrorMessage(e.errorType)).join(' ') });
        } else {
            setNameFieldValidation(defaultValidation);
        }
    }, [formValidationErrors, roi]);

    const handleColorChange = useCallback((color: [number, number, number]) => {
        dispatch(roiCustomizationColorUpdated({ roiId, color, isGlobalRoi }));
    }, [dispatch, isGlobalRoi, roiId]);

    const handleIncludedChange = useCallback((_: unknown, isIncluded: boolean) => {
        dispatch(roiCustomizationIncludedInModelUpdated({ roiId, isIncluded, isGlobalRoi }));
    }, [dispatch, isGlobalRoi, roiId]);


    /** Handle changing ROI's name and perform minor string validation in the process. */
    const handleNameChangeTextInput = useCallback((__: unknown, name: string) => {
        dispatch(roiCustomizationNameUpdated({ roiId, name, isGlobalRoi }));
    }, [dispatch, isGlobalRoi, roiId]);

    const handleNameChangeTextOverrideField = useCallback((name: string) => {
        dispatch(roiCustomizationNameUpdated({ roiId, name, isGlobalRoi }));
    }, [dispatch, isGlobalRoi, roiId]);

    /** Handle changing ROI's operation and perform minor string validation in the process. */
    const handleOperationChanged = useCallback((_: unknown, operation: string) => {
        dispatch(roiCustomizationOperationUpdated({ roiId, operation, isGlobalRoi }));
        if (operation) {
            setOperationFieldValidation(defaultValidation);
        } else {
            setOperationFieldValidation(emptyOperationValidation);
        }
    }, [dispatch, isGlobalRoi, roiId]);

    const handleConfirmOperationChanged = useCallback((operation: string) => {
        handleOperationChanged(undefined, operation);
        setIsRoiOperationModalOpen(false);
    }, [dispatch, isGlobalRoi, roiId]);


    const handleInterpretedTypeChange = useCallback((interpretedOperation: string) => {
        dispatch(roiInterpretedTypeUpdated({ roiId, interpretedType: interpretedOperation, isGlobalRoi }));
    }, [dispatch, isGlobalRoi, roiId]);

    const handleFmaCodeValueChanged = useCallback((_: unknown, value: string) => {
        dispatch(roiCodingSchemeUpdated({ roiId, codingScheme: { hasCodingScheme: true, codingSchemeCodeValue: value }, isGlobalRoi }));
    }, [dispatch, isGlobalRoi, roiId]);

    const handleFmaCodeMeaningChanged = useCallback((_: unknown, value: string) => {
        dispatch(roiCodingSchemeUpdated({ roiId, codingScheme: { hasCodingScheme: true, codingSchemeCodeMeaning: value }, isGlobalRoi }));
    }, [dispatch, isGlobalRoi, roiId]);

    const handleFmaCodeSchemeDesignatorChanged = useCallback((_: unknown, value: string) => {
        dispatch(roiCodingSchemeUpdated({ roiId, codingScheme: { hasCodingScheme: true, codingSchemeDesignator: value }, isGlobalRoi }));
    }, [dispatch, isGlobalRoi, roiId]);

    const handleOpenAdvancedRoiModal = useCallback(() => {
        setIsRoiOperationModalOpen(true);
    }, []);

    const handleCloseAdvancedRoiModal = useCallback(() => {
        setIsRoiOperationModalOpen(false);
    }, []);

    if (roi === undefined) {
        return null;
    }

    const isAdvancedRoiItem = !roi.isBuiltInRoi;

    const prefix = `${isGlobalRoi ? 'global-' : ''}roi-${roi.id}`;

    const nameFieldId = `${prefix}-name-override`;
    const colorFieldId = `${prefix}-color`;
    const includedFieldId = `${prefix}-included`;

    const highlight = isHighlightedItem ? 'highlighted-item' : '';

    const hasValidationError = validationError !== undefined && (validationError.type === CustomizationObjectType.Roi || validationError.type === CustomizationObjectType.PhysicalProperties);
    const validationErrorTitle = validationError && validationError.type === CustomizationObjectType.PhysicalProperties ? 'Validation error in physical properties settings' : 'Validation error';

    return (
        <>
            <Tr key={roiId} className={`roi-item ${hasValidationError ? 'validation-error' : ''} ${highlight}`}>
                <Td className={columnCssNames.modified}><div className="roi-item-is-modified" title="This customization has unsaved changes.">{roi.isModified ? '*' : <>&nbsp;</>}</div></Td>

                {extraColumnsBefore && extraColumnsBefore.map(extraColumn => extraColumn)}

                <Td dataLabel={columnIds.name} className={columnCssNames.name}>
                    <div title={roi.name}>
                        <FormGroup
                            fieldId={`field-${nameFieldId}`}
                            className={nameFieldValidation.validated === 'error' ? 'validation-error' : ''}
                        >
                            <div ref={scrollRef} className="roi-scroll-offset"></div>
                            {isAdvancedRoiItem ? (
                                <TextInput
                                    type="text"
                                    id={nameFieldId}
                                    onChange={handleNameChangeTextInput}
                                    value={roi.name}
                                    validated={nameFieldValidation.validated}
                                />
                            ) : (
                                <TextOverrideField
                                    defaultText={originalRoi ? originalRoi.name : roi.name}
                                    textValue={roi.name}
                                    id={nameFieldId}
                                    onChange={handleNameChangeTextOverrideField}
                                    allowEmptyField={true}
                                />
                            )}
                            {nameFieldValidation.validated !== 'default' && (
                                <ValidationHelperText
                                    validated={nameFieldValidation.validated}
                                    helperText={nameFieldValidation.helperTextInvalid}
                                />
                            )}
                        </FormGroup>
                    </div>
                </Td>

                {!hideIncludedOption && (
                    <Td dataLabel={columnIds.isIncluded} className={columnCssNames.isIncluded}>
                        <div>
                            <Switch
                                id={includedFieldId}
                                isChecked={roi.isIncluded}
                                onChange={handleIncludedChange}
                                label={t('toggle.roiIncludedInStructureSet.on')}
                                labelOff={t('toggle.roiIncludedInStructureSet.off')}
                            />
                        </div>
                    </Td>
                )}

                <Td dataLabel={columnIds.color} className={columnCssNames.color}>
                    <div>
                        <ColorPicker
                            color={roi.color}
                            onChange={handleColorChange}
                            textInputId={`${colorFieldId}-text-input`}
                        />
                    </div>
                </Td>

                <Td dataLabel={columnIds.operation} className={columnCssNames.operation}>
                    <div>
                        {isAdvancedRoiItem ? (
                            <FlexItem className="mv-flex-grow">
                                <div className="operation-column" title={roi.operation}>
                                    <FormGroup
                                        fieldId={`field-operation-input-${roi.id}`}
                                        className={operationFieldValidation.validated === 'error' ? 'validation-error' : ''}
                                    >
                                        <TextInputGroup>
                                            <TextInput
                                                id={`operation-input-${roi.id}`}
                                                className=""
                                                type="text"
                                                onChange={handleOperationChanged}
                                                value={roi.operation}
                                            />
                                            <AppendedButton onClick={handleOpenAdvancedRoiModal} />

                                        </TextInputGroup>
                                        {operationFieldValidation.validated !== 'default' && (
                                            <ValidationHelperText
                                                validated={operationFieldValidation.validated}
                                                helperText={operationFieldValidation.helperTextInvalid}
                                            />
                                        )}
                                    </FormGroup>
                                </div>
                            </FlexItem>
                        ) : (
                            <FlexItem className="mv-flex-grow">
                                <div className="operation-column" title={roi.operation}>
                                    <TextInput
                                        id={`operation-input-${roi.id}`}
                                        className=""
                                        type="text"
                                        onChange={handleOperationChanged}
                                        value={roi.operation}
                                        isDisabled={true}
                                    />
                                </div>
                            </FlexItem>
                        )}
                    </div>
                </Td>

                <Td dataLabel={columnIds.interpretedType} className={columnCssNames.interpretedType}>
                    <div>
                        <InterpretedTypeDropdown value={roi.interpretedType} onSelect={handleInterpretedTypeChange} />
                    </div>
                </Td>

                {!hideIdentificationCodeFields && (
                    <>
                        <Td dataLabel={columnIds.fmaCodeValue} className={columnCssNames.fmaCodeValue}>
                            <div title={roi.codingSchemeCodeValue}>
                                <TextInput
                                    id={`fmaCodeValue-input-${roi.id}`}
                                    type="text"
                                    onChange={handleFmaCodeValueChanged}
                                    value={roi.codingSchemeCodeValue || ''}
                                />
                            </div>
                        </Td>

                        <Td dataLabel={columnIds.fmaCodeMeaning} className={columnCssNames.fmaCodeMeaning}>
                            <div title={roi.codingSchemeCodeMeaning}>
                                <TextInput
                                    id={`fmaCodeMeaning-input-${roi.id}`}
                                    type="text"
                                    onChange={handleFmaCodeMeaningChanged}
                                    value={roi.codingSchemeCodeMeaning || ''}
                                />
                            </div>
                        </Td>

                        <Td dataLabel={columnIds.fmaCodeSchemeDesignator} className={columnCssNames.fmaCodeSchemeDesignator}>
                            <div title={roi.codingSchemeDesignator}>
                                <TextInput
                                    id={`fmaCodeSchemeDesignator-input-${roi.id}`}
                                    type="text"
                                    onChange={handleFmaCodeSchemeDesignatorChanged}
                                    value={roi.codingSchemeDesignator || ''}
                                />
                            </div>
                        </Td>
                    </>
                )}

                {extraColumnsAfter && extraColumnsAfter.map(extraColumn => extraColumn)}

                <Td isActionCell>
                    {rowActions && <ItemActionsColumn items={rowActions} />}
                </Td>
            </Tr>

            {hasValidationError && (
                <Tr className="roi-item validation-error validation-error-box selectable">
                    <Td dataLabel="Validation error" noPadding={true} colSpan={isGlobalRoi ? 8 : 100}>
                        <Alert variant="danger" isInline isPlain isExpandable title={`${validationErrorTitle}: ${validationError.message}`}>
                            {validationError.field && (<div>Field: {validationError.field}</div>)}
                            <div>Error type: {validationError.detail}</div>
                            <div>Error ctx: {validationError.ctx}</div>
                        </Alert>
                    </Td>
                    {isGlobalRoi && (
                        <>
                            <Td noPadding={true} className={expandedRow === 'includedModels' ? 'sub-table' : ''}></Td>
                            <Td noPadding={true} className={expandedRow === 'excludedModels' ? 'sub-table' : ''}></Td>
                            <Td noPadding={true}></Td>
                        </>
                    )}
                </Tr>
            )}

            {isRoiOperationModalOpen && (
                <RoiOperationModal isOpen={isRoiOperationModalOpen} onConfirm={handleConfirmOperationChanged} onClose={handleCloseAdvancedRoiModal} operation={roi.operation} />
            )}

        </>
    );
}

export default RoiItem;
