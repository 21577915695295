import { isArray } from "lodash-es";
import { CombinedLabeling, convertJsonObjectToLabeling, Labeling } from '../store/appVersion/labeling';
import { convertJsonObjectToLicenseStatuses, LicenseStatus } from '../store/license/license-status';
import { convertContouringJsonObjectToViewModels, convertContouringViewModelsToJson, convertSingleModelJsonToContouringOutput } from '../store/contouring/contouring-helpers';
import { ContouringCustomizationEntities, ContouringCustomizationEntitiesForExport, ContouringCustomizationOutputEntities } from '../store/contouring/contouring-types';
import { convertJsonObjectToMVisionClientList, MVisionAppClient } from '../store/configurationTarget/mvision-client-list';
import store from '../store/store';
import { JSONstringifyOrder } from '../util/string-convert';
import BackendApiInterface, { JsonValue } from './backend-api-interface';
import { convertDaemonConfigToJson, convertJsonObjectToDaemonConfig, convertJsonObjectToDaemonConfigs, DaemonConfig, DaemonCredentials, DaemonCredentialsSchema } from '../store/daemon/daemon-config';
import UserSettings, { UserSettingField } from "../store/user/user-settings";
import { AccessRights, parseAccessRights } from "../store/user/access-rights";
import { appConfigSelectors } from "../store/appConfig/appConfigSlice";
import { getBackendApi } from "../app-config";
import { convertDoseJsonObjectToViewModels, convertDoseViewModelsToJson, convertSingleModelJsonToDoseOutput } from "../store/dose/dose-helpers";
import { DoseCustomizationEntities, DoseCustomizationEntitiesForExport, DoseCustomizationOutputEntities } from "../store/dose/dose-types";
import { ModelType } from "../store/global-types/customization-types";



export type ModelsList = {
    availableModels: string[];
    deprecatedModels: string[];
}

/**
 * API wrapper for accessing backend's configuration API funtions. This class contains generic wrapper code that will,
 * in turn, call current correct implementation of the backend API client (cloud, local, dev-time, etc).
 */
export default class ConfigurationClient {

    /** Gets a backend client for any backend API calls matching whatever is the current
     * cloud backend.
     */
    private get api(): BackendApiInterface {
        const appConfig = appConfigSelectors.selectAppConfig(store.getState());
        if (appConfig === undefined) {
            throw new Error(`Could not get current app config.`);
        }

        return getBackendApi(appConfig);
    }

    /** Fetch ALL contouring model customizations. */
    public async fetchContouringCustomizationsAsync(appClient: MVisionAppClient | undefined): Promise<ContouringCustomizationEntities> {
        const jsonContents = await this.api.fetchContouringCustomizationAsync(appClient);
        const customizations = convertContouringJsonObjectToViewModels(jsonContents);
        return customizations;
    }

    /** Save the entire contouring customization config to backend in one go */
    public async saveContouringCustomizationAsync(appClient: MVisionAppClient | undefined, modelCustomization: ContouringCustomizationEntitiesForExport): Promise<boolean> {
        const jsonObject = convertContouringViewModelsToJson(modelCustomization);
        const json = JSONstringifyOrder(jsonObject);
        const success = await this.api.saveContouringCustomizationAsync(appClient, json);
        return success;
    }

    /** Deletes ALL contouring customizations for a specific app client which should revert them to default factory settings. */
    public async deleteContouringCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<boolean> {
        const success = await this.api.deleteContouringCustomizationAsync(appClient);
        return success;
    }

    public async fetchDefaultConfigurationForContouringOutput(modelName: string): Promise<ContouringCustomizationOutputEntities> {
        const jsonContents = await this.api.fetchDefaultConfigurationForContouringModelAsync(modelName);
        const customization = convertSingleModelJsonToContouringOutput(jsonContents);
        return customization;
    }

    /** Fetch ALL dose model customizations. */
    public async fetchDoseCustomizationsAsync(appClient: MVisionAppClient | undefined): Promise<DoseCustomizationEntities> {
        const jsonContents = await this.api.fetchDoseCustomizationAsync(appClient);
        const customizations = convertDoseJsonObjectToViewModels(jsonContents);
        return customizations;
    }

    /** Save the entire dose customization config to backend in one go */
    public async saveDoseCustomizationAsync(appClient: MVisionAppClient | undefined, modelCustomization: DoseCustomizationEntitiesForExport): Promise<boolean> {
        const jsonObject = convertDoseViewModelsToJson(modelCustomization);
        const json = JSONstringifyOrder(jsonObject);
        const success = await this.api.saveDoseCustomizationAsync(appClient, json);
        return success;
    }

    /** Deletes ALL dose customizations for a specific app client which should revert them to default factory settings. */
    public async deleteDoseCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<boolean> {
        const success = await this.api.deleteDoseCustomizationAsync(appClient);
        return success;
    }

    public async fetchDefaultConfigurationForDoseOutput(modelName: string): Promise<DoseCustomizationOutputEntities> {
        const jsonContents = await this.api.fetchDefaultConfigurationForDoseModelAsync(modelName);
        const customization = convertSingleModelJsonToDoseOutput(jsonContents);
        return customization;
    }

    /** Returns an array of all the segmentation model names that are available for current logged-in user (as per their auth),
      * or undefined if the list could not be retrieved correctly.
      */
    public async fetchAvailableModelsAsync(appClient: MVisionAppClient | undefined, modelType: ModelType): Promise<ModelsList | undefined> {
        const activeModelsList = await this.api.fetchActiveContouringModelsAsync(appClient, modelType);
        const deprecatedModels: string[] = [];

        if ('user_model_details' in activeModelsList && isArray(activeModelsList['user_model_details'])) {
            const availableModels: string[] = [];
            for (const model of activeModelsList['user_model_details']) {
                if ('model_name' in model) {
                    if (model['deprecated']) {
                        deprecatedModels.push(model['model_name']);
                    }
                    availableModels.push(model['model_name']);
                }
            }
            return { availableModels, deprecatedModels };
        }

        return undefined;
    }

    public async fetchLicenseStatusAsync(appClient: MVisionAppClient | undefined): Promise<LicenseStatus[]> {
        const jsonContents: any = await this.api.fetchLicenseStatusAsync(appClient);
        const licenseStatuses = convertJsonObjectToLicenseStatuses(jsonContents);
        return licenseStatuses;
    }

    public async saveLicenseAsync(appClient: MVisionAppClient | undefined, newLicense: string): Promise<boolean> {
        const success = await this.api.saveLicenseAsync(appClient, newLicense);

        return success;
    }

    /** Fetches all daemon configurations for a specific app client. */
    public async fetchDaemonConfigsAsync(appClient: MVisionAppClient | undefined): Promise<DaemonConfig[]> {
        const jsonContents: any = await this.api.fetchAllDaemonConfigsAsync(appClient);
        const daemonConfigs = convertJsonObjectToDaemonConfigs(jsonContents);
        return daemonConfigs;
    }

    /** Fetches a single daemon config or undefined. */
    public async fetchDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonClientId: string): Promise<DaemonConfig> {
        const json: any = await this.api.fetchDaemonConfigAsync(appClient, daemonClientId);
        const daemonConfig = convertJsonObjectToDaemonConfig(json);
        return daemonConfig;
    }

    public async regenerateCredentialsAsync(appClient: MVisionAppClient | undefined, sessionID: string): Promise<DaemonCredentials> {
        const json: any = await this.api.regenerateCredentialsAsync(appClient, sessionID);
        const result = DaemonCredentialsSchema.safeParse(json);

        if (result.success) {
            return result.data;
        } else {
            return { key: '', secret: '' };
        }
    }

    public async tokenAssociationApproveAsync(appClient: MVisionAppClient | undefined, token: string, sessionID: string) {
        await this.api.tokenAssociationApproveAsync(appClient, token, sessionID);
    }

    /** Saves a single daemon configuration. */
    public async saveDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig): Promise<boolean> {
        const jsonObject = convertDaemonConfigToJson(daemonConfig);
        const json = JSONstringifyOrder(jsonObject);
        const success = await this.api.saveDaemonConfigAsync(appClient, json);
        return success;
    }

    /** Resets a specific daemon configuration of a specific app client to default factory settings. */
    public async resetDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig): Promise<boolean> {
        const success = await this.api.resetDaemonConfigAsync(appClient, daemonConfig.sessionId);
        return success;
    }

    /** Deletes a specific daemon configuration of a specific app client. */
    public async deleteDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonConfig: DaemonConfig): Promise<boolean> {
        const success = await this.api.deleteDaemonConfigAsync(appClient, daemonConfig.sessionId);
        return success;
    }

    public async fetchLabelingAsync(modelTypes: ModelType[]): Promise<CombinedLabeling> {
        const labelJson = await this.api.fetchLabelingAsync(modelTypes);

        const labeling = convertJsonObjectToLabeling(labelJson.labeling, labelJson.backendVersion);
        return labeling;
    }

    public async fetchClientListAsync(): Promise<MVisionAppClient[]> {
        const clientListJsonContents = await this.api.fetchClientListAsync();

        const clientList = convertJsonObjectToMVisionClientList(clientListJsonContents);
        return clientList;
    }

    public async fetchAndParseAccessRights(): Promise<AccessRights> {
        const accessRightsJSON = await this.api.fetchAccessRightsAsync();
        const parsedAccessRights = parseAccessRights(accessRightsJSON);
        return parsedAccessRights;
    };


    public async fetchUserSettingsAsync(): Promise<UserSettings> {
        // see user-settings.tsx
        throw new Error('User settings are not implemented');

        // const userSettingsJson = await this.api.getUserSettingsAsync();

        // const userSettings = convertJsonObjectToUserSettings(userSettingsJson);
        // return userSettings;
    }

    public async saveUserSettingAsync(setting: UserSettingField, value: string | string[] | number | undefined): Promise<boolean> {
        // see user-settings.tsx
        throw new Error('User settings are not implemented');

        // return await this.api.saveUserSettingAsync(getUserSettingJsonFieldName(setting), JSON.stringify(value));
    }

    public async saveUserSettingsAsync(settings: { setting: UserSettingField, value: JsonValue }[]): Promise<boolean> {
        // see user-settings.tsx
        throw new Error('User settings are not implemented');

        // const settingsJson: {[x: string]: JsonValue} = {};
        // for (const setting of settings) {
        //     settingsJson[getUserSettingJsonFieldName(setting.setting)] = setting.value;
        // }
        // return await this.api.saveUserSettingsAsync(settingsJson);
    }
}
