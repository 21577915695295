import React, { useCallback, useEffect, useState } from 'react';
import { FormGroup, TextInput, Form, Button, Panel, PanelHeader, PanelMain, PanelMainBody, Toolbar, ToolbarContent, ToolbarItem, Checkbox, FormFieldGroup, FormFieldGroupHeader, Grid, GridItem, Modal, Spinner, Alert, Divider, Title, Text, TextVariants, Accordion, HelperText, FormHelperText, } from '@patternfly/react-core';
import { LockIcon, LockOpenIcon, TrashIcon, TimesIcon, SaveIcon, UndoIcon, PlusIcon, AngleRightIcon, AngleDownIcon } from '@patternfly/react-icons/';
import { cloneDeep, isString } from "lodash-es";

import { useTranslation } from 'react-i18next';
import { DaemonConfig, getDefaultTarget, isDaemonConfigValid } from '../../store/daemon/daemon-config';
import { useImmer } from 'use-immer';
import { getNumber } from '../../util/math';
import { useDispatch, useSelector } from 'react-redux';
import { DaemonValidationError } from '../../store/global-types/store-errors';
import { notEmpty } from '../../util/filter';
import SmallAccordionButton from '../customization/SmallAccordionButton';
import { StoreState } from '../../store/store';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import { daemonConfigDeleted, daemonConfigReset, daemonConfigSaved, daemonSliceSelectors } from '../../store/daemon/daemonSlice';
import ValidationHelperText from '../../components/validation-helper-text';

interface DaemonConfigItemProps {
    daemonConfig: DaemonConfig,
    runningNumber: number | undefined,
    isBootstrap?: boolean,
    onConfigSaved?: Function
}

type ValidationState = { validated: 'default' | 'success' | 'warning' | 'error', helperTextInvalid: string | null };

enum SaveFinishedTextType { None, Save, Reset, Remove };

const defaultValidation: ValidationState = { validated: 'default', helperTextInvalid: null };
const mustBeNumberValidation: ValidationState = { validated: 'error', helperTextInvalid: 'Must be a number.' };

/** Returns true if given string converts to a valid, usable number. Used in form validation. */
const isValidNumber = (value: string): boolean => {
    const number = getNumber(value);
    return number !== undefined && !isNaN(number);
}

/** Error DTO field names that could be found from error messages received from the backend. */
const ErrorDtoFields = {
    keepMapping: 'keep_mapping_h',
    maxTries: 'max_tries',
    backendPollInterval: 'backend_poll_interval_s',
    backendConfigInterval: 'backend_config_interval_s',
    retainAttributes: 'retain_attributes',
    passThroughAttributes: 'pass_through_attributes',
    targetAeTitle: 'ae_title',
    targetIp: 'ip',
    targetPort: 'port',
};

const DaemonConfigItem = (props: DaemonConfigItemProps) => {
    const { runningNumber } = props;

    const dispatch = useDispatch();
    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const isSaveInProgress = useSelector((state: StoreState) => state.daemon.isDaemonConfigSaveInProgress || state.daemon.isDaemonConfigResetInProgress || state.daemon.isDaemonConfigDeleteInProgress);
    const daemonConfigSaveErrorMessage = useSelector((state: StoreState) => state.daemon.daemonConfigSaveError);
    const daemonConfigSaveErrors = useSelector(daemonSliceSelectors.selectDaemonValidationErrorEntities);

    const [isEditingEnabled, setIsEditingEnabled] = useState(!!props.isBootstrap);
    const [isConfirmRemoveTargetModalOpen, setIsConfirmRemoveTargetModalOpen] = useState(false);
    const [removeTargetIndex, setRemoveTargetIndex] = useState<number | undefined>(undefined);
    const [isConfirmSaveChangesModalOpen, setIsConfirmSaveChangesModalOpen] = useState(false);
    const [isConfirmUndoChangesModalOpen, setIsConfirmUndoChangesModalOpen] = useState(false);
    const [isConfirmResetToDefaultsModalOpen, setIsConfirmResetToDefaultsModalOpen] = useState(false);
    const [isConfirmRemoveModalOpen, setIsConfirmRemoveModalOpen] = useState(false);
    const [isTryingToSave, setIsTryingToSave] = useState(false);
    const [isTryingToReset, setIsTryingToReset] = useState(false);
    const [isTryingToRemove, setIsTryingToRemove] = useState(false);
    const [isSaveFinishedTextVisible, setIsSaveFinishedTextVisible] = React.useState(SaveFinishedTextType.None);
    const [saveFailedText, setSaveFailedText] = React.useState<string | undefined>(undefined);
    const [saveErrors, setSaveErrors] = React.useState<DaemonValidationError[]>([]);

    const [keepMappingFieldValidation, setKeepMappingFieldValidation] = useState<ValidationState>(defaultValidation);
    const [maxTriesFieldValidation, setMaxTriesFieldValidation] = useState<ValidationState>(defaultValidation);
    const [backendPollIntervalFieldValidation, setBackendPollIntervalFieldValidation] = useState<ValidationState>(defaultValidation);
    const [backendConfigIntervalFieldValidation, setBackendConfigIntervalFieldValidation] = useState<ValidationState>(defaultValidation);
    const [retainAttributesFieldValidation, setRetainAttributesFieldValidation] = useState<ValidationState>(defaultValidation);
    const [passThroughAttributesFieldValidation, setPassThroughAttributesFieldValidation] = useState<ValidationState>(defaultValidation);

    // note -- this is an object with numbered keys, NOT an array! TODO: can be converted into an array quite safely
    const [targetPortValidation, setTargetPortValidation] = useImmer<{ [index: number]: ValidationState }>({});


    // Create a copy of supplied original daemon config that we'll actually modify
    const [daemonConfig, setDaemonConfig] = useImmer(cloneDeep(props.daemonConfig));

    const [isIdentificationItemsExpanded, setIndentificationItemsExpanded] = useState(!!props.isBootstrap);
    const [isDaemonConfigExpanded, setIsDaemonConfigExpanded] = useState(!!props.isBootstrap);

    const handleIdentificationItemsExpanded = useCallback(() => {
        setIndentificationItemsExpanded(!isIdentificationItemsExpanded);
    }, [isIdentificationItemsExpanded]);

    const handleDaemonConfigExpanded = useCallback(() => {
        setIsDaemonConfigExpanded(!isDaemonConfigExpanded);
    }, [isDaemonConfigExpanded]);

    const resetValidation = useCallback(() => {
        setKeepMappingFieldValidation(defaultValidation);
        setMaxTriesFieldValidation(defaultValidation);
        setBackendPollIntervalFieldValidation(defaultValidation);
        setBackendConfigIntervalFieldValidation(defaultValidation);
        setRetainAttributesFieldValidation(defaultValidation);
        setPassThroughAttributesFieldValidation(defaultValidation);
    }, [])

    const handleStartEditing = useCallback(() => {
        setIsSaveFinishedTextVisible(SaveFinishedTextType.None);
        setIsEditingEnabled(true);
        setSaveFailedText(undefined);
        setSaveErrors([]);
    }, []);

    const handleStopEditing = useCallback(() => {
        resetValidation();
        setTargetPortValidation({});
        setIsEditingEnabled(false);
    }, [resetValidation, setTargetPortValidation]);

    // show backend errors in UI when received
    useEffect(() => {
        if (saveErrors.length > 0) {
            for (const saveError of saveErrors) {
                const validationError: ValidationState = { validated: 'error', helperTextInvalid: `${saveError.message} (${saveError.detail})` };
                if (saveError.field === ErrorDtoFields.keepMapping) { setKeepMappingFieldValidation(validationError); }
                else if (saveError.field === ErrorDtoFields.maxTries) { setMaxTriesFieldValidation(validationError); }
                else if (saveError.field === ErrorDtoFields.backendPollInterval) { setBackendPollIntervalFieldValidation(validationError); }
                else if (saveError.field === ErrorDtoFields.backendConfigInterval) { setBackendConfigIntervalFieldValidation(validationError); }
                else if (saveError.field === ErrorDtoFields.retainAttributes) { setRetainAttributesFieldValidation(validationError); }
                else if (saveError.field === ErrorDtoFields.passThroughAttributes) { setPassThroughAttributesFieldValidation(validationError); }
                else if (isString(saveError.field) && [ErrorDtoFields.targetAeTitle, ErrorDtoFields.targetIp, ErrorDtoFields.targetPort].includes(saveError.field)) {
                    // showing errors on correct target is not currently supported so display it as a top-level daemon config error message
                    setSaveFailedText(`Configuration error in a target, field ${saveError.field}: "${validationError.helperTextInvalid}"` || 'An error occurred when trying to save daemon configuration. Please check that the targets have been configured correctly.');
                }
                else {
                    console.log('Unrecognised error:');
                    console.log(saveError);
                }
            }
        }
    }, [saveErrors]);

    const handleShowRemoveTargetModal = useCallback((index: number) => { setRemoveTargetIndex(index); setIsConfirmRemoveTargetModalOpen(true); }, []);
    const handleHideRemoveTargetModal = useCallback(() => { setRemoveTargetIndex(undefined); setIsConfirmRemoveTargetModalOpen(false); }, []);
    const handleConfirmRemoveTarget = useCallback(() => {

        // remove target from daemon config
        setDaemonConfig(draft => {
            if (removeTargetIndex !== undefined && removeTargetIndex < draft.targets.length) {
                draft.targets.splice(removeTargetIndex, 1);
            }
        });

        // fix form validation for targets
        setTargetPortValidation(draft => {
            if (removeTargetIndex !== undefined && removeTargetIndex < daemonConfig.targets.length) {
                draft[removeTargetIndex] = defaultValidation;
                if (removeTargetIndex < daemonConfig.targets.length - 1) {
                    for (let i = removeTargetIndex + 1; i < daemonConfig.targets.length; i++) {
                        draft[i - 1] = draft[i];
                        draft[i] = defaultValidation;
                    }
                }
            }
        })
        handleHideRemoveTargetModal();
    }, [daemonConfig.targets.length, handleHideRemoveTargetModal, removeTargetIndex, setDaemonConfig, setTargetPortValidation]);

    const handleShowSaveChangesModal = useCallback(() => { setIsConfirmSaveChangesModalOpen(true); }, []);
    const handleHideSaveChangesModal = useCallback(() => { setIsConfirmSaveChangesModalOpen(false); }, []);
    const handleConfirmSaveChanges = useCallback(() => {
        setIsTryingToSave(true);
        setSaveFailedText(undefined);
        setSaveErrors([]);
        dispatch(daemonConfigSaved({ appClient: currentTarget, daemonConfig: daemonConfig }));
        setIsSaveFinishedTextVisible(SaveFinishedTextType.None);
        handleHideSaveChangesModal();
        if (!!props.onConfigSaved) {
            props.onConfigSaved(daemonConfig);
        }
    }, [dispatch, currentTarget, daemonConfig, handleHideSaveChangesModal]);

    const handleShowUndoChangesModal = useCallback(() => { setIsConfirmUndoChangesModalOpen(true); }, []);
    const handleHideUndoChangesModal = useCallback(() => { setIsConfirmUndoChangesModalOpen(false); }, []);
    const handleConfirmUndoChanges = useCallback(() => {
        setDaemonConfig(draft => draft = cloneDeep(props.daemonConfig));
        handleStopEditing();
        setIsSaveFinishedTextVisible(SaveFinishedTextType.None);
        handleHideUndoChangesModal();
    }, [handleHideUndoChangesModal, handleStopEditing, props.daemonConfig, setDaemonConfig]);

    const handleShowResetToDefaultsModal = useCallback(() => { setIsConfirmResetToDefaultsModalOpen(true); }, []);
    const handleHideResetToDefaultsModal = useCallback(() => { setIsConfirmResetToDefaultsModalOpen(false); }, []);
    const handleConfirmResetToDefaults = useCallback(() => {
        setIsTryingToReset(true);
        setSaveFailedText(undefined);
        setSaveErrors([]);
        dispatch(daemonConfigReset({ appClient: currentTarget, daemonConfig: props.daemonConfig }));
        setIsSaveFinishedTextVisible(SaveFinishedTextType.None);
        handleHideResetToDefaultsModal();
    }, [currentTarget, dispatch, handleHideResetToDefaultsModal, props.daemonConfig]);

    const handleShowRemoveModal = useCallback(() => { setIsConfirmRemoveModalOpen(true); }, []);
    const handleHideRemoveModal = useCallback(() => { setIsConfirmRemoveModalOpen(false); }, []);
    const handleConfirmRemove = useCallback(() => {
        setIsTryingToRemove(true);
        setSaveFailedText(undefined);
        setSaveErrors([]);
        dispatch(daemonConfigDeleted({ appClient: currentTarget, daemonConfig: props.daemonConfig }));
        setIsSaveFinishedTextVisible(SaveFinishedTextType.None);
        handleHideRemoveModal();
    }, [currentTarget, dispatch, handleHideRemoveModal, props.daemonConfig]);

    const updateDaemonConfig = useCallback((changed: Partial<DaemonConfig>) => {
        setDaemonConfig(draft => draft = Object.assign(draft, changed));
    }, [setDaemonConfig]);

    const handleAETitleChanged = useCallback((_: unknown, aeTitle: string) => { updateDaemonConfig({ aeTitle }); }, [updateDaemonConfig]);
    const handleListenPortChanged = useCallback((_: unknown, listenPort: string) => { updateDaemonConfig({ listenPort }); }, [updateDaemonConfig]);
    const handleListenIPChanged = useCallback((_: unknown, listenIp: string) => { updateDaemonConfig({ listenIp }); }, [updateDaemonConfig]);

    const handleForwardImagesChanged = useCallback((_: unknown,  checked: boolean) => { updateDaemonConfig({ forwardImages: checked }); }, [updateDaemonConfig]);
    const handleAuditLogChanged = useCallback((_: unknown,  checked: boolean) => { updateDaemonConfig({ auditLog: checked }); }, [updateDaemonConfig]);
    const handleRetainAttributesChanged = useCallback((_: unknown,  value: string) => { updateDaemonConfig({ retainAttributes: value.split(',') }); }, [updateDaemonConfig]);
    const handlePassThroughAttributesChanged = useCallback((_: unknown,  value: string) => { updateDaemonConfig({ passThroughAttributes: value.split(',') }); }, [updateDaemonConfig]);

    const handleKeepMappingInHChanged = useCallback((_: unknown,  value: string) => {
        updateDaemonConfig({ keepMappingInH: value });
        setKeepMappingFieldValidation(isValidNumber(value) ? defaultValidation : mustBeNumberValidation);
    }, [updateDaemonConfig]);

    const handleMaxTriesChanged = useCallback((_: unknown,  value: string) => {
        updateDaemonConfig({ maxTries: value });
        setMaxTriesFieldValidation(isValidNumber(value) ? defaultValidation : mustBeNumberValidation);
    }, [updateDaemonConfig]);

    const handleBackendPollIntervalInSChanged = useCallback((_: unknown,  value: string) => {
        updateDaemonConfig({ backendPollIntervalInS: value });
        setBackendPollIntervalFieldValidation(isValidNumber(value) ? defaultValidation : mustBeNumberValidation);
    }, [updateDaemonConfig]);

    const handleBackendConfigIntervalInSChanged = useCallback((_: unknown,  value: string) => {
        updateDaemonConfig({ backendConfigIntervalInS: value });
        setBackendConfigIntervalFieldValidation(isValidNumber(value) ? defaultValidation : mustBeNumberValidation);
    }, [updateDaemonConfig]);

    const handleAddTargetClicked = useCallback(() => {
        const nextIndex = daemonConfig.targets.length - 1 + 1;

        // add new target
        setDaemonConfig(draft => { draft.targets.push(getDefaultTarget()); });

        // set default validation
        setTargetPortValidation(draft => { draft[nextIndex] = defaultValidation; });
    }, [daemonConfig.targets.length, setDaemonConfig, setTargetPortValidation]);

    const handleTargetValueChanged = useCallback((index: number, prop: 'aeTitle' | 'ip' | 'port', value: string) => {
        // set actual value
        setDaemonConfig(draft => {
            const target = draft.targets[index];
            if (target !== undefined) {
                target[prop] = value;
            }
        });

        // set validation
        if (prop === 'port') {
            setTargetPortValidation(draft => {
                draft[index] = isValidNumber(value) ? defaultValidation : mustBeNumberValidation;
            });
        }
    }, [setDaemonConfig, setTargetPortValidation]);

    // handle coming back from a save
    useEffect(() => {
        // catch when saving is over
        if (!isSaveInProgress && isTryingToSave) {
            setIsTryingToSave(false);
            setIsSaveFinishedTextVisible(SaveFinishedTextType.Save);

            // set failure text from latest failure if available
            if (daemonConfigSaveErrorMessage !== null) {
                setSaveFailedText(daemonConfigSaveErrorMessage);
                if (daemonConfigSaveErrors) {
                    const matchingErrors = Object.values(daemonConfigSaveErrors).filter(notEmpty).filter(e => e.id.includes(daemonConfig.sessionId));
                    setSaveErrors(matchingErrors);
                }
            } else {
                // if save was successful, then close editing tools
                handleStopEditing();
            }
        }
    }, [daemonConfig.sessionId, daemonConfigSaveErrorMessage, daemonConfigSaveErrors, handleStopEditing, isSaveInProgress, isTryingToSave]);

    // handle coming back from a reset
    useEffect(() => {
        // catch when reset is over
        if (!isSaveInProgress && isTryingToReset) {
            setIsTryingToReset(false);
            handleStopEditing();
            setIsSaveFinishedTextVisible(SaveFinishedTextType.Reset);

            // set failure text from latest failure if available
            if (daemonConfigSaveErrorMessage !== null) {
                setSaveFailedText(daemonConfigSaveErrorMessage);
                if (daemonConfigSaveErrors) {
                    const matchingErrors = Object.values(daemonConfigSaveErrors).filter(notEmpty).filter(e => e.id.includes(daemonConfig.sessionId));
                    setSaveErrors(matchingErrors);
                }
            } else {
                // replace local in-state daemon config item with the one we just received after reset, if available
                setDaemonConfig(cloneDeep(props.daemonConfig));
            }
        }
    }, [daemonConfig.sessionId, daemonConfigSaveErrorMessage, daemonConfigSaveErrors, handleStopEditing, isSaveInProgress, isTryingToReset, props.daemonConfig, setDaemonConfig]);

    const { t } = useTranslation();
    const id = daemonConfig.sessionId;

    return (<>
        <Panel variant="raised">
            <Accordion onClick={handleDaemonConfigExpanded}>
                <PanelHeader className={isEditingEnabled ? 'daemon-config-header daemon-config-header-unlocked' : 'daemon-config-header daemon-config-header-locked'}>
                    <p>
                        {runningNumber && (<span className="running-number">#{runningNumber}: </span>)}
                        {t('daemonConfigPage.configItemHeader', { portNumber: daemonConfig.listenPort, sessionId: daemonConfig.sessionId })}
                    </p>
                    {isDaemonConfigExpanded ? (<AngleDownIcon className="daemon-config-item-icon" />) : (<AngleRightIcon className="daemon-config-item-icon" />)}
                </PanelHeader>
            </Accordion>
            <PanelMain className={isDaemonConfigExpanded ? 'is-expanded' : 'is-collapsed'}>
                <PanelMainBody>

                    <Toolbar isSticky>
                        <ToolbarContent>
                            <ToolbarItem><Button variant="secondary" isDisabled={isEditingEnabled} onClick={handleStartEditing}>{isEditingEnabled ? <LockOpenIcon className="daemon-config-icon" /> : <LockIcon className="daemon-config-icon" />} {t('daemonConfigPage.toolbar.modify')}</Button></ToolbarItem>
                            {isEditingEnabled && (<>
                                <ToolbarItem><Button variant="secondary" isDisabled={!isEditingEnabled || !isDaemonConfigValid(daemonConfig) || isSaveInProgress} onClick={handleShowSaveChangesModal}>{isTryingToSave ? (<Spinner  size="md" />) : (<SaveIcon className="daemon-config-icon" />)} {t('daemonConfigPage.toolbar.save')}</Button></ToolbarItem>
                                <ToolbarItem><Button variant="secondary" isDisabled={!isEditingEnabled || isSaveInProgress} onClick={handleShowUndoChangesModal}><UndoIcon className="daemon-config-icon" /> {t('daemonConfigPage.toolbar.undo')}</Button></ToolbarItem>
                                <ToolbarItem><Button variant="secondary" isDanger isDisabled={!isEditingEnabled || isSaveInProgress} onClick={handleShowResetToDefaultsModal}>{isTryingToReset ? (<Spinner  size="md" />) : (<TimesIcon className="daemon-config-icon" />)} {t('daemonConfigPage.toolbar.reset')}</Button></ToolbarItem>
                                <ToolbarItem><Button variant="secondary" isDanger isDisabled={!isEditingEnabled || isSaveInProgress} onClick={handleShowRemoveModal}>{isTryingToRemove ? (<Spinner  size="md" />) : (<TrashIcon className="daemon-config-icon" />)} {t('daemonConfigPage.toolbar.remove')}</Button></ToolbarItem>
                            </>)}
                            {isSaveFinishedTextVisible === SaveFinishedTextType.Save && (
                                <Alert variant={saveFailedText === undefined ? "success" : "danger"} isInline isPlain title={saveFailedText === undefined ? t('daemonConfigPage.save.successful') : saveFailedText || t('daemonConfigPage.save.failed')} />
                            )}
                            {isSaveFinishedTextVisible === SaveFinishedTextType.Reset && (
                                <Alert variant={saveFailedText === undefined ? "success" : "danger"} isInline isPlain title={saveFailedText === undefined ? t('daemonConfigPage.reset.successful') : saveFailedText || t('daemonConfigPage.reset.failed')} />
                            )}
                            {isSaveFinishedTextVisible === SaveFinishedTextType.Remove && (
                                // Technically, the "remove was successful" text will never ever be shown in this component as this item will disappear!
                                (<Alert variant={saveFailedText === undefined ? "success" : "danger"} isInline isPlain title={saveFailedText === undefined ? t('daemonConfigPage.remove.successful') : saveFailedText || t('daemonConfigPage.remove.failed')} />)
                            )}
                        </ToolbarContent>
                    </Toolbar>

                    <Form className="daemon-config-form">

                        <Title headingLevel='h1'>Identification</Title>

                        <SmallAccordionButton
                            isActive={isIdentificationItemsExpanded}
                            onClick={handleIdentificationItemsExpanded}
                            label={t('daemonConfigPage.form.identificationAttributes')}
                        />
                        <FormGroup className={isIdentificationItemsExpanded ? 'is-expanded' : 'is-collapsed'}>
                            <FormGroup
                                label={t('daemonConfigPage.form.sessionId')}
                                fieldId={`dc-sessionid-${id}`}
                            >
                                <TextInput
                                    id={`dc-sessionid-${id}`}
                                    value={daemonConfig.sessionId}
                                    isDisabled={true}
                                />
                                <HelperText>
                                    {t('daemonConfigPage.form.sessionId.description')}
                                </HelperText>
                            </FormGroup>
                            <FormGroup
                                label={t('daemonConfigPage.form.aeTitle')}
                                fieldId={`dc-aetitle-${id}`}
                            >
                                <TextInput
                                    id={`dc-aetitle-${id}`}
                                    value={daemonConfig.aeTitle}
                                    isDisabled={!props.isBootstrap}
                                    onChange={handleAETitleChanged}
                                />
                                <HelperText>
                                    {t('daemonConfigPage.form.aeTitle.description')}
                                </HelperText>
                            </FormGroup>
                            <FormGroup
                                label={t('daemonConfigPage.form.listenPort')}
                                fieldId={`dc-listenport-${id}`}
                                type="number"
                            >
                                <TextInput
                                    id={`dc-listenport-${id}`}
                                    value={daemonConfig.listenPort}
                                    isDisabled={!props.isBootstrap}
                                    onChange={handleListenPortChanged}
                                />
                                <HelperText>
                                    {t('daemonConfigPage.form.listenPort.description')}
                                </HelperText>
                            </FormGroup>
                            <FormGroup
                                label={t('daemonConfigPage.form.listenIp')}
                                fieldId={`dc-listenip-${id}`}
                            >
                                <TextInput
                                    id={`dc-listenip-${id}`}
                                    value={daemonConfig.listenIp}
                                    isDisabled={!props.isBootstrap}
                                    onChange={handleListenIPChanged}
                                />
                                <HelperText>
                                    {t('daemonConfigPage.form.listenIp.description')}
                                </HelperText>
                            </FormGroup>

                        </FormGroup>
                        {!isIdentificationItemsExpanded && (<div className="section-hint"><Text component={TextVariants.small}>{t('daemonConfigPage.IdAttributeExpandable')}</Text></div>)}



                        <Divider />
                        <Title headingLevel='h1'>Forwarding details</Title>

                        <FormFieldGroup
                            header={<FormFieldGroupHeader
                                titleText={{ text: <span className="daemon-targets-title">{t('daemonConfigPage.form.targets')}</span>, id: `dc-targets-header-${id}` }}
                                titleDescription={t('daemonConfigPage.form.targets.description')}
                                actions={
                                    <>
                                        <Button variant="primary" isDisabled={!isEditingEnabled || isSaveInProgress} onClick={handleAddTargetClicked}><PlusIcon className="daemon-config-icon" /> {t('daemonConfigPage.form.addTarget')}</Button>
                                    </>}
                            />}
                        >
                            <Grid hasGutter>
                                {daemonConfig.targets.map((target, index) => (
                                    <React.Fragment key={index}>
                                        <GridItem span={4}>
                                            <FormGroup
                                                label={t('daemonConfigPage.form.target.aeTitle')}
                                                fieldId={`dc-aetitle-${id}-${index}`}
                                            >
                                                <TextInput
                                                    id={`dc-aetitle-${id}-${index}`}
                                                    value={target.aeTitle}
                                                    isDisabled={!isEditingEnabled || isSaveInProgress}
                                                    onChange={evt => handleTargetValueChanged(index, 'aeTitle', evt.currentTarget.value)}
                                                />
                                                <HelperText>
                                                    {t('daemonConfigPage.form.target.aeTitle.description')}
                                                </HelperText>
                                            </FormGroup>
                                        </GridItem>
                                        <GridItem span={4}>
                                            <FormGroup
                                                label={t('daemonConfigPage.form.target.ip')}
                                                fieldId={`dc-ip-${id}-${index}`}
                                            >
                                                <TextInput
                                                    id={`dc-ip-${id}-${index}`}
                                                    value={target.ip}
                                                    isDisabled={!isEditingEnabled || isSaveInProgress}
                                                    onChange={evt => handleTargetValueChanged(index, 'ip', evt.currentTarget.value)}
                                                />
                                                <HelperText>
                                                    {t('daemonConfigPage.form.target.ip.description')}
                                                </HelperText>
                                            </FormGroup>
                                        </GridItem>
                                        <GridItem span={3}>
                                            <FormGroup
                                                label={t('daemonConfigPage.form.target.port')}
                                                fieldId={`dc-port-${id}-${index}`}
                                                type="number"
                                            >
                                                <TextInput
                                                    id={`dc-port-${id}-${index}`}
                                                    value={target.port}
                                                    isDisabled={!isEditingEnabled || isSaveInProgress}
                                                    onChange={evt => handleTargetValueChanged(index, 'port', evt.currentTarget.value)}
                                                    validated={targetPortValidation[index]?.validated || 'default'}

                                                />
                                                <HelperText>
                                                    {t('daemonConfigPage.form.target.port.description')}
                                                </HelperText>
                                            </FormGroup>
                                        </GridItem>
                                        <GridItem span={1}>
                                            <Toolbar className="daemon-target-row-toolbar">
                                                <ToolbarContent>
                                                    <ToolbarItem>
                                                        <Button variant="plain" aria-label="Remove" title={t('daemonConfigPage.form.target.removeTarget.tooltip')} isDisabled={!isEditingEnabled || isSaveInProgress} onClick={() => handleShowRemoveTargetModal(index)}><TrashIcon /></Button>
                                                    </ToolbarItem>
                                                </ToolbarContent>
                                            </Toolbar>
                                        </GridItem>
                                    </React.Fragment>
                                ))}
                            </Grid>

                        </FormFieldGroup>

                        <FormGroup
                            fieldId={`dc-forward-${id}`}
                        >
                            <Checkbox
                                id={`dc-forward-${id}`}
                                label={t('daemonConfigPage.form.forwardImages')}
                                isChecked={daemonConfig.forwardImages}
                                isDisabled={!isEditingEnabled || isSaveInProgress}
                                onChange={handleForwardImagesChanged} />
                            <HelperText>
                                {t('daemonConfigPage.form.forwardImages.description')}
                            </HelperText>
                        </FormGroup>
                        <FormGroup
                            label={t('daemonConfigPage.form.retainAttributes')}
                            fieldId={`dc-retainattributes-${id}`}
                        >
                            <TextInput
                                id={`dc-retainattributes-${id}`}
                                value={daemonConfig.retainAttributes.join(',')}
                                isDisabled={!isEditingEnabled || isSaveInProgress}
                                onChange={handleRetainAttributesChanged}
                                validated={retainAttributesFieldValidation.validated}
                            />
                            {retainAttributesFieldValidation.validated !== 'default' && (
                                <ValidationHelperText
                                    validated={retainAttributesFieldValidation.validated}
                                    helperText={retainAttributesFieldValidation.helperTextInvalid}
                                />
                            )}
                        </FormGroup>
                        <FormGroup
                            label={t('daemonConfigPage.form.passThroughAttributes')}
                            fieldId={`dc-passthrough-${id}`}
                        >
                            <TextInput
                                id={`dc-passthrough-${id}`}
                                value={daemonConfig.passThroughAttributes.join(',')}
                                isDisabled={!isEditingEnabled || isSaveInProgress}
                                onChange={handlePassThroughAttributesChanged}
                                validated={passThroughAttributesFieldValidation.validated}
                            />
                            {passThroughAttributesFieldValidation.validated !== 'default' && (
                                <ValidationHelperText
                                    validated={passThroughAttributesFieldValidation.validated}
                                    helperText={passThroughAttributesFieldValidation.helperTextInvalid}
                                />
                            )}
                        </FormGroup>

                        <Divider />
                        <Title headingLevel='h1'>Advanced</Title>

                        <FormGroup
                            fieldId={`dc-audit-${id}`}
                        >
                            <FormHelperText>{t('daemonConfigPage.form.auditLog.description')}</FormHelperText>
                            <Checkbox
                                id={`dc-audit-${id}`}
                                label={t('daemonConfigPage.form.auditLog')}
                                isChecked={daemonConfig.auditLog}
                                isDisabled={!isEditingEnabled || isSaveInProgress}
                                onChange={handleAuditLogChanged} />
                        </FormGroup>
                        <FormGroup
                            label={t('daemonConfigPage.form.keepMapping')}
                            fieldId={`dc-keepmapping-${id}`}
                            type="number"
                        >
                            <TextInput
                                id={`dc-keepmapping-${id}`}
                                value={daemonConfig.keepMappingInH}
                                isDisabled={!isEditingEnabled || isSaveInProgress}
                                onChange={handleKeepMappingInHChanged}
                                validated={keepMappingFieldValidation.validated}
                            />
                            {keepMappingFieldValidation.validated !== 'default' && (
                                <ValidationHelperText
                                    validated={keepMappingFieldValidation.validated}
                                    helperText={keepMappingFieldValidation.helperTextInvalid}
                                />
                            )}
                        </FormGroup>
                        <FormGroup
                            label={t('daemonConfigPage.form.maxTries')}
                            fieldId={`dc-maxtries-${id}`}
                            type="number"
                        >
                            <TextInput
                                id={`dc-maxtries-${id}`}
                                value={daemonConfig.maxTries}
                                isDisabled={!isEditingEnabled || isSaveInProgress}
                                onChange={handleMaxTriesChanged}
                                validated={maxTriesFieldValidation.validated}
                            />
                            {maxTriesFieldValidation.validated !== 'default' && (
                                <ValidationHelperText
                                    validated={maxTriesFieldValidation.validated}
                                    helperText={maxTriesFieldValidation.helperTextInvalid}
                                />
                            )}
                        </FormGroup>
                        <FormGroup
                            label={t('daemonConfigPage.form.backendPollInterval')}
                            fieldId={`dc-backendpollinterval-${id}`}
                            type="number"
                        >
                            <TextInput
                                id={`dc-backendpollinterval-${id}`}
                                value={daemonConfig.backendPollIntervalInS}
                                isDisabled={!isEditingEnabled || isSaveInProgress}
                                onChange={handleBackendPollIntervalInSChanged}
                                validated={backendPollIntervalFieldValidation.validated}
                            />
                            {backendPollIntervalFieldValidation.validated !== 'default' && (
                                <ValidationHelperText
                                    validated={backendPollIntervalFieldValidation.validated}
                                    helperText={backendPollIntervalFieldValidation.helperTextInvalid}
                                />
                            )}
                        </FormGroup>
                        <FormGroup
                            label={t('daemonConfigPage.form.backendConfigInterval')}
                            fieldId={`dc-backendconfiginterval-${id}`}
                            type="number"
                        >
                            <TextInput
                                id={`dc-backendconfiginterval-${id}`}
                                value={daemonConfig.backendConfigIntervalInS}
                                isDisabled={!isEditingEnabled || isSaveInProgress}
                                onChange={handleBackendConfigIntervalInSChanged}
                                validated={backendConfigIntervalFieldValidation.validated}
                            />
                            {backendConfigIntervalFieldValidation.validated !== 'default' && (
                                <ValidationHelperText
                                    validated={backendConfigIntervalFieldValidation.validated}
                                    helperText={backendConfigIntervalFieldValidation.helperTextInvalid}
                                />
                            )}
                        </FormGroup>

                    </Form>

                </PanelMainBody>
            </PanelMain>
        </Panel>
        <Modal
            className="daemon-config-modal"
            title={t('daemonConfigPage.modal.removeTarget.title')}
            aria-label="confirmation modal for daemon configuration target deletion"
            variant="small"
            isOpen={isConfirmRemoveTargetModalOpen}
            showClose={false}
            actions={[
                <Button variant="danger" key="confirm" onClick={handleConfirmRemoveTarget}><TrashIcon className="daemon-config-icon" /> {t('daemonConfigPage.modal.removeTarget.confirm')}</Button>,
                <Button variant="tertiary" key="cancel" onClick={handleHideRemoveTargetModal}>{t('daemonConfigPage.modal.removeTarget.cancel')}</Button>
            ]}
        >
            {t('daemonConfigPage.modal.removeTarget.description')}
        </Modal>
        <Modal
            className="daemon-config-modal"
            title={t('daemonConfigPage.modal.saveChanges.title')}
            aria-label="confirmation modal for saving all changes to daemon configuration"
            variant="small"
            isOpen={isConfirmSaveChangesModalOpen}
            showClose={false}
            actions={[
                <Button variant="primary" key="confirm" onClick={handleConfirmSaveChanges}><SaveIcon className="daemon-config-icon" /> {t('daemonConfigPage.modal.saveChanges.confirm')}</Button>,
                <Button variant="tertiary" key="cancel" onClick={handleHideSaveChangesModal}>{t('daemonConfigPage.modal.saveChanges.cancel')}</Button>
            ]}
        >
            {t('daemonConfigPage.modal.saveChanges.description')}
        </Modal>
        <Modal
            className="daemon-config-modal"
            title={t('daemonConfigPage.modal.undoChanges.title')}
            aria-label="confirmation modal for undoing all changes to daemon configuration"
            variant="small"
            isOpen={isConfirmUndoChangesModalOpen}
            showClose={false}
            actions={[
                <Button variant="danger" key="confirm" onClick={handleConfirmUndoChanges}><UndoIcon className="daemon-config-icon" /> {t('daemonConfigPage.modal.undoChanges.confirm')}</Button>,
                <Button variant="tertiary" key="cancel" onClick={handleHideUndoChangesModal}>{t('daemonConfigPage.modal.undoChanges.cancel')}</Button>
            ]}
        >
            {t('daemonConfigPage.modal.undoChanges.description')}
        </Modal>
        <Modal
            className="daemon-config-modal"
            title={t('daemonConfigPage.modal.reset.title')}
            aria-label="confirmation modal for resetting daemon configuration to factory defaults"
            variant="small"
            isOpen={isConfirmResetToDefaultsModalOpen}
            showClose={false}
            actions={[
                <Button variant="danger" key="confirm" onClick={handleConfirmResetToDefaults}><TimesIcon className="daemon-config-icon" /> {t('daemonConfigPage.modal.reset.confirm')}</Button>,
                <Button variant="tertiary" key="cancel" onClick={handleHideResetToDefaultsModal}>{t('daemonConfigPage.modal.reset.cancel')}</Button>
            ]}
        >
            <p>{t('daemonConfigPage.modal.reset.description.paragraph1')}</p>
            <p>{t('daemonConfigPage.modal.reset.description.paragraph2')}</p>

        </Modal>
        <Modal
            className="daemon-config-modal"
            title={t('daemonConfigPage.modal.remove.title')}
            aria-label="confirmation modal for removing daemon configuration"
            variant="small"
            isOpen={isConfirmRemoveModalOpen}
            showClose={false}
            actions={[
                <Button variant="danger" key="confirm" onClick={handleConfirmRemove}><TrashIcon className="daemon-config-icon" /> {t('daemonConfigPage.modal.remove.confirm')}</Button>,
                <Button variant="tertiary" key="cancel" onClick={handleHideRemoveModal}>{t('daemonConfigPage.modal.remove.cancel')}</Button>
            ]}
        >
            <p>{t('daemonConfigPage.modal.remove.description.paragraph1')}</p>
            <p>{t('daemonConfigPage.modal.remove.description.paragraph2')}</p>

        </Modal>
    </>);
}

export default DaemonConfigItem;
