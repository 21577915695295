import { PageSection, PageSectionVariants, TextContent, Text, DataList, Toolbar, ToolbarContent, ToolbarItem, Button, Modal, ModalVariant, Alert, EmptyState, EmptyStateBody, Tab, TabTitleText, Tabs, ToolbarGroup, EmptyStateHeader, TabsProps } from '@patternfly/react-core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isString } from "lodash-es";
import { SaveIcon, UndoIcon, ExclamationCircleIcon } from '@patternfly/react-icons'

import './model-selection-page.css';
import { CustomizationBase, CustomizationBaseWithModelName, CustomizationObjectType, ModelSelectionScope, ModelType } from '../../store/global-types/customization-types';
import { BackendValidationErrorViewModel } from '../../store/global-types/store-errors';
import { useTranslation } from 'react-i18next';
import { hasUserName } from '../../store/configurationTarget/mvision-client-list';
import { StoreState } from '../../store/store';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import CustomizationFilters from '../customization/CustomizationFilters';
import ContourSelectionRules from './contour/ContourSelectionRules';
import DoseSelectionRules from './dose/DoseSelectionRules';


const getHeadingForModelRules = (customizationId: string): string => {
    return getHeadingForModelName(customizationId);
}

const getHeadingForModelName = (customizationId: string): string => {
    return `heading-modelRules-${customizationId}`;
}

/** Scrolls viewport to given heading, if it exists on the page.
 * @param heading The heading to scroll to. Must match to an id attribute
 * in a DOM element.
 */
const jumpToHeading = (heading: string) => {
    const domElement = document.querySelector(`#${heading}`);
    if (domElement) {
        domElement.scrollIntoView({ behavior: 'smooth' });
    }
}

const getTab = (label: string, customization: CustomizationBase | undefined, validationErrorEntities: Record<string, BackendValidationErrorViewModel>) => {
    if (customization === undefined) {
        throw new Error(`Customization base ${label} was undefined`);
    }

    const { isModified } = customization;
    const hasValidationError =
        customization.aeTitleRules.some(aId => validationErrorEntities[aId]?.type === CustomizationObjectType.AeTitleRule) ||
        customization.dicomRules.some(dId => validationErrorEntities[dId]?.type === CustomizationObjectType.DicomRule);
    const title = isModified ? `${label}*` : label;
    const className = isModified ? 'model-selection-nav-modified' : '';
    const titleComponent = <span className={`${hasValidationError ? 'validation-error' : ''}`}>{hasValidationError && <span className="validation-error-icon"><ExclamationCircleIcon /></span>}<span>{title}</span></span>
    return (<Tab
        key={customization.id}
        eventKey={customization.id}
        title={<TabTitleText className={className}>{titleComponent}</TabTitleText>}
        onSelect={() => jumpToHeading(getHeadingForModelName(customization.id))}
    />);
}

const getUndoModalTitle = (undoScope: ModelSelectionScope) => {
    switch (undoScope) {
        case ModelSelectionScope.All:
            return 'Undo all changes to model selection?';
        case ModelSelectionScope.ModelRules:
            return 'Undo all selection changes for this model?';
        case ModelSelectionScope.AeTitleRule:
            return 'Undo changes to this AE Title rule?';
        case ModelSelectionScope.DicomRule:
            return 'Undo changes to this DICOM rule?';
        case ModelSelectionScope.DicomAttributeRule:
            return 'Undo changes to this DICOM rule attribute condition?';
        case ModelSelectionScope.None:
        default:
            return 'Undo changes?';
    }
}

const getUndoModalText = (undoScope: ModelSelectionScope) => {
    switch (undoScope) {
        case ModelSelectionScope.All:
            return <Text>Do you want to undo all changes to model selection and revert the entire model selection back to previously saved configuration?</Text>;
        case ModelSelectionScope.ModelRules:
            return <Text>Do you want to undo all selection-related changes for this one model and revert them back to previously saved configuration? Any changes to other models are kept.</Text>;
        case ModelSelectionScope.AeTitleRule:
            return <Text>Do you want to undo changes to this AE Title rule and revert it back to previously saved configuration?</Text>;
        case ModelSelectionScope.DicomRule:
            return <Text>Do you want to undo changes to this DICOM rule and revert it back to previously saved configuration?</Text>
        case ModelSelectionScope.DicomAttributeRule:
            return <Text>Do you want to undo changes to this DICOM rule attribute condition and revert it back to previously saved configuration?</Text>;
        case ModelSelectionScope.None:
        default:
            return <Text>Do you want to undo all changes and revert them back to previously saved configuration? <b>Note: this operation will be performed both for your selection and customization changes!</b></Text>
    }
}

export type ModelSelectionUndoState = {
    isModalOpen: boolean,
    scope: ModelSelectionScope,
    id?: string | undefined,
}

export type ModelSelectionPageProps = {
    modelType: ModelType,
    customizationBaseIdsInOrder: CustomizationBaseWithModelName[],
    customizationBaseEntities: Record<string, CustomizationBase>,
    isSavingInProgress: boolean,
    isAnyCustomizationModelModified: boolean,
    isEverySelectionRuleValid: boolean,
    isContentAvailable: boolean,
    /** Return TRUE if save was succesful, false otherwise. */
    onSave: () => boolean,
    /** Return TRUE if undo was succesful, false otherwise. */
    onUndo: () => boolean,
    /** Return TRUE if single undo was succesful, false otherwise. */
    onSingleUndo: (scope: ModelSelectionScope, id: string) => boolean,
    customizationFetchError: string | null,
    validationErrorEntities: Record<string, BackendValidationErrorViewModel>,
    modelCustomizationSaveError: string | null,
}

const ModelSelectionPage = (props: ModelSelectionPageProps) => {

    const { modelType, isSavingInProgress, onSave, onUndo, onSingleUndo,
        customizationBaseIdsInOrder, customizationBaseEntities,
        isAnyCustomizationModelModified, isEverySelectionRuleValid, isContentAvailable,
        customizationFetchError, validationErrorEntities, modelCustomizationSaveError,
    } = props;

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [activeModelTab, setActiveModelTab] = useState('');
    const [isSaveModalOpen, setSaveModalOpen] = useState(false);
    const [undoState, setUndoState] = useState<ModelSelectionUndoState>({ isModalOpen: false, scope: ModelSelectionScope.None, id: undefined });
    const [isUndoConfirmationModalOpen, setUndoConfirmationModalOpenState] = useState<boolean>(false);

    const handleCloseUndoModal = useCallback(() => setUndoState({ isModalOpen: false, scope: ModelSelectionScope.None, id: undefined }), []);
    const handleOpenSaveDialog = useCallback(() => { setSaveModalOpen(true); }, []);
    const handleCloseSaveDialog = useCallback(() => { setSaveModalOpen(false); }, []);

    const handleOpenUndoDialog = useCallback(() => {
        setUndoConfirmationModalOpenState(true);
    }, []);

    const handleCloseUndoDialog = useCallback(() => {
        setUndoConfirmationModalOpenState(false);
    }, []);

    const handleUndo = useCallback(() => {
        if (onUndo()) {
            handleCloseUndoDialog();
        }
    }, [dispatch, handleCloseUndoDialog]);

    const handleSave = useCallback(() => {
        if (onSave()) {
            handleCloseSaveDialog();
        }
    }, [dispatch, handleCloseSaveDialog]);

    const handleCancelSave = useCallback(() => {
        handleCloseSaveDialog();
    }, [handleCloseSaveDialog]);

    const handleTabChange = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>, eventKey: string | number) => {
        if (isString(eventKey)) {
            jumpToHeading(getHeadingForModelName(eventKey));
            setActiveModelTab(eventKey);
        }
    }, []);

    const handleSingleUndo = useCallback((scope: ModelSelectionScope, id: string) => {
        if (onSingleUndo(scope, id)) {
            handleCloseUndoModal();
        }
    }, [dispatch, handleCloseUndoModal]);

    useEffect(() => {
        if (undoState.isModalOpen && undoState.id !== undefined) {
            handleSingleUndo(undoState.scope, undoState.id);
        }
    }, [handleSingleUndo, undoState]);

    const getSelectionRulesForm = useCallback((customizationBaseId: string) => {
        switch (modelType) {
            case ModelType.Contouring:
                return (
                    <ContourSelectionRules
                        key={customizationBaseId}
                        customizationBaseId={customizationBaseId}
                        setUndoState={setUndoState}
                        heading={getHeadingForModelRules(customizationBaseId)}
                        showMultipleOutputsWarning={false}
                    />
                );
            case ModelType.Dose:
                return (
                    <DoseSelectionRules
                        key={customizationBaseId}
                        customizationBaseId={customizationBaseId}
                        setUndoState={setUndoState}
                        heading={getHeadingForModelRules(customizationBaseId)}
                        showMultipleOutputsWarning={false}
                    />
                );
            default:
                throw new Error(`Unsupported model type`);
        }
    }, [setUndoState]);

    if (modelType !== ModelType.Contouring && modelType !== ModelType.Dose) {
        throw new Error(`Unsupported model type for model selection page (${modelType})`);
    }

    const isSaveModalActuallyOpen = isSaveModalOpen || isSavingInProgress;
    const isToolbarEnabled = !isSaveModalActuallyOpen && isAnyCustomizationModelModified && isEverySelectionRuleValid;

    return (
        <>
            {appConfig && appConfig.showErrorsInUi && customizationFetchError !== null ? (
                <PageSection variant={PageSectionVariants.light} className="selectable">
                    <EmptyState>
                        <EmptyStateHeader titleText="An error occurred" headingLevel="h4" />
                        <EmptyStateBody>
                            An error occurred when trying to load customization page: {customizationFetchError}
                        </EmptyStateBody>
                    </EmptyState>
                </PageSection>
            ) : isContentAvailable ? (
                <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
                    <div className="selection-page-split">
                            <div className="selection-page-sidebar unselectable">
                            <div className="selection-filters">
                                <CustomizationFilters modelType={modelType} />
                            </div>

                            <PageSection variant={PageSectionVariants.light}>
                                <div className="page-nav">
                                    <Tabs isVertical onSelect={handleTabChange} activeKey={activeModelTab} className="compact">
                                        {customizationBaseIdsInOrder.map((c) => getTab(c.label, customizationBaseEntities[c.customizationBaseId], validationErrorEntities))}
                                    </Tabs>
                                </div>
                            </PageSection>
                        </div>

                        <div className="selection-page-content">
                            <PageSection variant={PageSectionVariants.light}>

                                <div className="model-selection-main-toolbar" id="model-selection-main-toolbar">
                                    {appConfig && appConfig.showErrorsInUi && modelCustomizationSaveError !== null && (
                                        <div className="toolbar-error selectable">
                                            <Alert variant="danger" isInline title="An error occurred when trying to save model selection">
                                                <p>{modelCustomizationSaveError}</p>
                                            </Alert>
                                        </div>
                                    )}
                                    <Toolbar>
                                        <ToolbarContent>
                                            <ToolbarGroup variant="button-group" >
                                                <ToolbarItem>
                                                    <Button
                                                        variant="primary"
                                                        icon={<SaveIcon />}
                                                        isDisabled={!isToolbarEnabled}
                                                        // className="save-changes-button"
                                                        onClick={handleOpenSaveDialog}>{t('common.saveAllChanges')}</Button>
                                                </ToolbarItem>
                                                <ToolbarItem>
                                                    <Button
                                                        variant="primary"
                                                        icon={<UndoIcon />}
                                                        isDisabled={!isToolbarEnabled}
                                                        // className="revert-changes-button"
                                                        onClick={handleOpenUndoDialog}>{t('common.undoAllChanges')}</Button>
                                                </ToolbarItem>
                                            </ToolbarGroup>
                                        </ToolbarContent>
                                    </Toolbar>
                                </div>

                                <DataList aria-label={`Model selection configuration`} isCompact>
                                    {customizationBaseIdsInOrder.map(c => (getSelectionRulesForm(c.customizationBaseId)))}
                                </DataList>

                            </PageSection>
                        </div>
                    </div>
                </PageSection>) : (
                <PageSection variant={PageSectionVariants.light}>
                    <EmptyState>
                        <EmptyStateHeader titleText={<>{t('selectConfigurationTarget.title')}</>} headingLevel="h4" />
                        <EmptyStateBody>{t('selectConfigurationTarget.message')}</EmptyStateBody>
                    </EmptyState>
                </PageSection>
            )}

            <Modal
                variant={ModalVariant.large}
                title={`Save current model selection configuration${hasUserName(currentTarget) ? ` for ${currentTarget!.userName}` : ''}?`}
                isOpen={isSaveModalActuallyOpen}
                onClose={handleCancelSave}
                actions={[
                    <Button key="confirmSave" variant="primary" isLoading={isSavingInProgress} onClick={handleSave}>{t('common.saveAll')}</Button>,
                    <Button key="cancel" disabled={isSavingInProgress} variant="tertiary" onClick={handleCancelSave}>{t('common.cancel')}</Button>
                ]}
            >
                Do you want to overwrite existing model selection configuration{hasUserName(currentTarget) ? ` for ${currentTarget!.userName}` : ''} with your changes? Any
                previous selection configurations are lost. Note that you can always revert back to default settings. <b>Note: this operation will be performed both for your selection and customization changes!</b>
            </Modal>

            <Modal
                isOpen={isUndoConfirmationModalOpen}
                onClose={handleCloseUndoDialog}
                variant={ModalVariant.large}
                title={getUndoModalTitle(undoState.scope)}
                actions={[
                    <Button key="confirmUndo" variant="danger" onClick={handleUndo}>{t('common.undoAllChanges')}</Button>,
                    <Button key="cancelUndo" variant="secondary" onClick={handleCloseUndoDialog}>{t('common.keepChanges')}</Button>
                ]}
            >
                {getUndoModalText(undoState.scope)}
            </Modal>

        </>
    );
}

export default ModelSelectionPage;
