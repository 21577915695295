import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MVisionAppClient } from "./mvision-client-list";
import { naturalSort } from "../../util/sort";


export type ConfigurationTargetSliceState = {
    /** List of configuration targets (MVision app clients) available to current user,
     * or undefined if not yet initialized. */
    configurationTargetList: MVisionAppClient[] | undefined,

    /** Error received when trying to fetch configuration target list, or null.
     * TODO: Consider a more simplified error messaging state handling. */
    configurationTargetListError: string | null,

    /** Current user-selected configuration target (app client), or nothing. */
    current: MVisionAppClient | undefined,

    /** True if configuration target list has been fetched, false otherwise. */
    isConfigurationTargetListFetched: boolean,

    /** True if app is currently fetching configuration target related data, false otherwise. */
    isFetchingConfigurationTargetData: boolean,

    /** Hold the credentials error if any */
    credentialsError: string | null;

    /** Holds the key if daemon credentials were regenerated */
    credentialsKey: string | null,

    /** Holds the secret if daemon credentials were regenerated */
    credentialsSecret: string | null
};


export const initialState: ConfigurationTargetSliceState = {
    configurationTargetList: undefined,
    configurationTargetListError: null,
    current: undefined,
    isConfigurationTargetListFetched: false,
    isFetchingConfigurationTargetData: false,
    credentialsError: null,
    credentialsKey: null,
    credentialsSecret: null
};

/** Redux store slice for interacting with configuration targets that user has access to (these
 * used to be called app clients).
) */
const configurationTargetSlice = createSlice({
    name: 'configurationTarget',
    initialState,
    reducers: {
        /** Sets configuration targets available to current user to the given input.
         * @param action payload.configurationTargetList: List of configuration targets to use.
         * @param action payload.errorMessage: Optional error message.
         */
        configurationTargetListSet(state, action: PayloadAction<{ configurationTargetList: MVisionAppClient[] | null, errorMessage?: string }>) {
            const { configurationTargetList, errorMessage } = action.payload;

            if (configurationTargetList !== null) {
                state.configurationTargetList = configurationTargetList;
            }

            if (!errorMessage) {
                state.isConfigurationTargetListFetched = true;
            }

            state.configurationTargetListError = errorMessage !== undefined ? errorMessage : null;
        },

        /**
         * Stores the daemon credentials if/when they were generated
         * @param action.key Credentials key
         * @param action.secret Credentials secret
         * @param action.error An optional error message.
         */
        daemonCredentialsSet(state, action: PayloadAction<{ key: string | null, secret: string | null, error?: string }>) {
            const { key, secret, error } = action.payload;

            state.credentialsError = error || null;
            state.credentialsKey = !error ? key : null;
            state.credentialsSecret = !error ? secret : null;
        },

        /** Sets currently active configuration target.
         * @param action payload: userId of the configuration target to use, or undefined if
         * any current selection should be unselected.
         */
        currentConfigurationTargetSet(state, action: PayloadAction<string | undefined>) {
            const userId = action.payload;

            const newAppClient = userId === undefined ? undefined : state.configurationTargetList ? state.configurationTargetList.find(cl => cl.userId === userId) : undefined;
            state.current = newAppClient;
        },

        credentialsRegenerationRequested(state, action: PayloadAction<{ appClient: string | undefined, sessionID: string | undefined}>) {},

        tokenAssociationApproved(state, action: PayloadAction<{ token: string | undefined, sessionID: string | undefined}>) {},

        /** Signals that configuration target list should be fetched (or reloaded). */
        configurationTargetListFetched() {
            // this is an empty action and is only used for signalling in sagas
        },

        /** Mark that app has started fetching data for a configuration target. */
        fetchConfigurationTargetDataStarted(state) {
            state.isFetchingConfigurationTargetData = true;
        },

        /** Mark that app has finished fetching data for a configuration target. */
        fetchConfigurationTargetDataFinished(state) {
            state.isFetchingConfigurationTargetData = false;
        },
    },
    selectors: {
        selectConfigurationTargetList: (state): MVisionAppClient[] | undefined => state.configurationTargetList ?
            naturalSort(state.configurationTargetList, 'userName') : state.configurationTargetList,
        selectCurrent: (state) => state.current,
        daemonCredentials: (state) => ({ key: state.credentialsKey, secret: state.credentialsSecret, error: state.credentialsError }),
        selectIsFetchingConfigurationTargetData: (state) => state.isFetchingConfigurationTargetData,
    }
});

export const { configurationTargetListSet, daemonCredentialsSet, currentConfigurationTargetSet, credentialsRegenerationRequested, tokenAssociationApproved, configurationTargetListFetched, fetchConfigurationTargetDataStarted, fetchConfigurationTargetDataFinished } = configurationTargetSlice.actions;

// uncomment if these are needed locally
// const localSelectors = configurationTargetSlice.getSelectors();

export const { getInitialState, selectors: configurationTargetSelectors } = configurationTargetSlice;

export default configurationTargetSlice.reducer;
