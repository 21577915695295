import { useEffect, useState } from 'react';
import { PageSection, TextContent, Text, PageSectionVariants, FormGroup, Form, TextArea, InputGroup, Button, Alert, AlertActionCloseButton, InputGroupItem } from '@patternfly/react-core';
import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from '../../store/store';
import { appConfigSelectors } from '../../store/appConfig/appConfigSlice';
import { configurationTargetSelectors } from '../../store/configurationTarget/configurationTargetSlice';
import { updateLicenseStarted } from '../../store/license/licenseSlice';

const UpdateLicense = () => {

    const dispatch = useDispatch();

    const [licenseKeyBoxValue, setLicenseKeyBoxValue] = useState('');
    const [showLicenseUpdatedAlert, setLicenseUpdatedAlert] = useState(false);

    const currentTarget = useSelector(configurationTargetSelectors.selectCurrent);
    const appConfig = useSelector(appConfigSelectors.selectAppConfig);
    const isUpdatingLicense = useSelector((state: StoreState) => state.license.isUpdatingLicense);
    const licenseUpdateError = useSelector((state: StoreState) => state.license.licenseUpdateError);

    // these are used to control showing temporal alert elements in the UI
    const successfulLicenseUpdatesCount = useSelector((state: StoreState) => state.license.successfulLicenseUpdatesCount);
    const [successfulLicenseUpdatesCountOnPageLoad] = useState(successfulLicenseUpdatesCount);

    useEffect(() => {
        // reset new license key in UI, but only run this at start
        // or after a successful license update
        setLicenseKeyBoxValue('');

        // show the alert that the license update was successful
        if (successfulLicenseUpdatesCount > successfulLicenseUpdatesCountOnPageLoad) {
            setLicenseUpdatedAlert(true);
        }
    }, [successfulLicenseUpdatesCount, successfulLicenseUpdatesCountOnPageLoad]);

    const handleUpdateLicenseClick = () => {
        if (licenseKeyBoxValue && !isUpdatingLicense) {
            dispatch(updateLicenseStarted({ newLicense: licenseKeyBoxValue, appClient: currentTarget }));
        }
    }

    return (
        <PageSection variant={PageSectionVariants.light} className="limit-width update-license-section">

            <TextContent className="content-header">
                <Text component="h2">Update license</Text>
            </TextContent>

            {appConfig && appConfig.showErrorsInUi && licenseUpdateError !== null && (
                <PageSection className="selectable">
                    <Alert variant="danger" isInline title="An error occurred when trying to update license">
                        <p>An error occurred when trying to update license. Please double-check that the license key was entered correctly and is valid. If the error persists please contact MVision support. Error message from server:</p>
                        <p>{licenseUpdateError}</p>
                    </Alert>
                </PageSection>
            )}

            {showLicenseUpdatedAlert && (
                <PageSection>
                    <Alert variant="success" title="License updated" actionClose={<AlertActionCloseButton onClose={() => setLicenseUpdatedAlert(false)} />}>
                        <p>License was updated successfully.</p>
                    </Alert>
                </PageSection>
            )}

            <Form isHorizontal>
                <FormGroup
                    label="Add license key"
                    fieldId="new-license-key" >
                    <InputGroup>
                        <InputGroupItem isFill ><TextArea value={licenseKeyBoxValue} onChange={(_event, v) => setLicenseKeyBoxValue(v)} resizeOrientation='vertical' id="new-license-key" /></InputGroupItem>
                        <InputGroupItem><Button onClick={handleUpdateLicenseClick}>Update license</Button></InputGroupItem>
                    </InputGroup>
                </FormGroup>
            </Form>

        </PageSection>
    );
}

export default UpdateLicense;
