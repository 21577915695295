import { Dispatch } from "@reduxjs/toolkit";
import { MVisionAppClient } from "../store/configurationTarget/mvision-client-list";
import { ModelType } from "../store/global-types/customization-types";
import { DeploymentConfigInfo } from "../store/appConfig/appDeploymentInfoTypes";

/** Query parameter used by backend API to specify caller's session ID. */
export const QUERY_PARAM_SESSION_ID = 'sessionId';

export type JsonValue = string | string[] | number | undefined;

export type LabelJsonWrapper = { modelType: ModelType, labeling: any };

export type CombinedVersionInfoJson = {
    labeling: LabelJsonWrapper[],
    backendVersion: any,
};

/**
 * Generic backend client API wrapper interface. All backend
 * client classes should implement this interface.
 * 
 * Note that the application does not support changing the used backend client
 * on the fly. Each different backend client should have their own build (and
 * deployment, if relevant) of the entire application.
 */
export default interface BackendApiInterface {
    /** One-time initialization of the backend client with the application. */
    init(dispatch: Dispatch, deploymentInfo: DeploymentConfigInfo): void,

    fetchContouringCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<any>,
    saveContouringCustomizationAsync(appClient: MVisionAppClient | undefined, json: string): Promise<boolean>,
    deleteContouringCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<boolean>,
    fetchActiveContouringModelsAsync(appClient: MVisionAppClient | undefined, modelType: ModelType): Promise<any>,
    /** Returns default configuration for a single contouring model */
    fetchDefaultConfigurationForContouringModelAsync(modelName: string): Promise<any>,

    fetchDoseCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<any>,
    saveDoseCustomizationAsync(appClient: MVisionAppClient | undefined, json: string): Promise<boolean>,
    deleteDoseCustomizationAsync(appClient: MVisionAppClient | undefined): Promise<boolean>,
    fetchDefaultConfigurationForDoseModelAsync(modelName: string): Promise<any>,

    fetchLicenseStatusAsync(appClient: MVisionAppClient | undefined): Promise<any>,
    fetchAllDaemonConfigsAsync(appClient: MVisionAppClient | undefined): Promise<any>,
    fetchDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonSessionId: string): Promise<any>,
    saveDaemonConfigAsync(appClient: MVisionAppClient | undefined, json: string): Promise<boolean>,
    regenerateCredentialsAsync(appClient: MVisionAppClient | undefined, sessionID: string): Promise<any>,
    tokenAssociationApproveAsync(appClient: MVisionAppClient | undefined, token: string, sessionID: string): any,
    resetDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonSessionId: string): Promise<boolean>,
    deleteDaemonConfigAsync(appClient: MVisionAppClient | undefined, daemonSessionId: string): Promise<boolean>,
    saveLicenseAsync(appClient: MVisionAppClient | undefined, newLicense: string): Promise<boolean>,
    fetchLabelingAsync(modelTypes: ModelType[]): Promise<CombinedVersionInfoJson>,
    fetchClientListAsync(): Promise<any>,
    fetchAccessRightsAsync(): Promise<any>,

    getUserSettingsAsync(): Promise<any>;
    saveUserSettingAsync(setting: string, jsonValue: string): Promise<boolean>;
    saveUserSettingsAsync(settingsJson: { [x: string]: JsonValue; }): Promise<boolean>;

    /** Returns a component that will wrap the entire application */
    getAppWrapperComponent(): (props: React.PropsWithChildren<{}>) => JSX.Element,
}

